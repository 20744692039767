{
  "name": "wonder",
  "version": "1.5.14",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=127.0.0.1",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:prod": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "lint:es": "eslint -c .eslintrc.js --ext .ts src",
    "lint:scss": "stylelint --syntax scss 'src/**/*.scss' --fix",
    "hmr": "ng serve --hmr -c hmr --disable-host-check",
    "serve-spa-dev-docker": "docker-compose -f docker-compose.yml up --build -d"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^14.2.0",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/flex-layout": "^14.0.0-beta.40",
    "@angular/forms": "^18.2.8",
    "@angular/localize": "^18.2.8",
    "@angular/material": "^14.2.0",
    "@angular/material-moment-adapter": "^14.2.0",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@bluehalo/ngx-leaflet": "^18.0.2",
    "@bluehalo/ngx-leaflet-draw": "^18.0.4",
    "@bluehalo/ngx-leaflet-markercluster": "^18.0.1",
    "@mat-datetimepicker/core": "^14.0.0",
    "@mat-datetimepicker/moment": "^14.0.0",
    "@microsoft/signalr": "^6.0.8",
    "@ng-matero/extensions": "^14.2.0",
    "@ng-matero/extensions-moment-adapter": "^14.0.0",
    "@ngneat/until-destroy": "^9.2.3",
    "@ngrx/component-store": "^18.1.0",
    "@ngrx/effects": "^18.1.0",
    "@ngrx/entity": "^18.1.0",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/store": "^18.1.0",
    "@ngrx/store-devtools": "^18.1.0",
    "@ngx-formly/core": "^6.0.0",
    "@ngx-formly/material": "^6.0.0",
    "@ngx-formly/schematics": "6.0.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "apexcharts": "^3.26.0",
    "chart.js": "^3.9.1",
    "chartjs-plugin-annotation": "^2.0.1",
    "dms-conversion": "^3.1.1",
    "file-saver": "^2.0.2",
    "guid-typescript": "^1.0.9",
    "immer": "^9.0.16",
    "leaflet": "^1.9.4",
    "leaflet-draw": "^1.0.4",
    "leaflet-measure": "^3.1.0",
    "leaflet.markercluster": "^1.5.3",
    "leaflet.polylinemeasure": "^3.0.0",
    "lodash": "^4.17.20",
    "luxon": "^3.3.0",
    "moment": "^2.29.1",
    "moment-timezone": "^0.5.33",
    "ng-block-ui": "^4.0.1",
    "ng-matero": "^14.0.0",
    "ng2-charts": "^4.0.0",
    "ngx-colors": "^3.2.0",
    "ngx-mat-select-search": "^5.0.0",
    "ngx-progressbar": "^9.0.0",
    "ngx-toastr": "^15.0.0",
    "ngx-uploader": "^16.0.0",
    "oidc-client": "^1.10.1",
    "parse-dms": "0.0.5",
    "photoviewer": "^3.6.5",
    "rxjs": "~7.5.6",
    "screenfull": "^6.0.2",
    "tslib": "^2.1.0",
    "tus-js-client": "^3.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.9",
    "@angular-devkit/schematics": "18.2.9",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular/cli": "^18.2.9",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@angularclass/hmr": "^3.0.0",
    "@schematics/angular": "^14.2.1",
    "@stylistic/eslint-plugin-ts": "^2.9.0",
    "@types/file-saver": "^2.0.1",
    "@types/jasmine": "~4.3.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/leaflet": "^1.9.14",
    "@types/leaflet-draw": "^1.0.11",
    "@types/leaflet.markercluster": "^1.5.4",
    "@types/leaflet.polylinemeasure": "^1.0.4",
    "@types/lodash": "^4.14.165",
    "@types/luxon": "^3.3.0",
    "@types/node": "^14.14.22",
    "@typescript-eslint/eslint-plugin": "^8.11.0",
    "@typescript-eslint/eslint-plugin-tslint": "^7.0.2",
    "@typescript-eslint/parser": "^8.11.0",
    "cpr": "^3.0.1",
    "eslint": "9.13.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.4.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~4.4.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.3.17",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "prettier": "^2.2.1",
    "rimraf": "^3.0.2",
    "stylelint": "^14.11.0",
    "stylelint-config-recommended-scss": "^7.0.0",
    "stylelint-config-standard": "^28.0.0",
    "stylelint-scss": "^4.3.0",
    "ts-node": "~10.9.1",
    "typescript": "~5.4.5"
  }
}
