import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import * as i1 from 'ngx-progressbar';
const NG_PROGRESS_HTTP_CONFIG = new InjectionToken('ngProgressHttpConfig');
class NgProgressInterceptor {
  constructor(ngProgress, config) {
    this.ngProgress = ngProgress;
    this._inProgressCount = 0;
    this._config = {
      id: 'root',
      silentApis: [],
      matcher: undefined
    };
    this._config = config ? {
      ...this._config,
      ...config
    } : this._config;
  }
  intercept(req, next) {
    // Ignore by request headers
    if (req.headers.has('ignoreProgressBar')) {
      return next.handle(req.clone({
        headers: req.headers.delete('ignoreProgressBar')
      }));
    }
    // Ignore silent api requests
    if (this.checkUrl(req)) {
      return next.handle(req);
    }
    this._inProgressCount++;
    this._progressRef = this.ngProgress.ref(this._config.id);
    if (!this._progressRef.isStarted) {
      this._progressRef.start();
    }
    return next.handle(req).pipe(finalize(() => {
      this._inProgressCount--;
      if (this._inProgressCount === 0) {
        this._progressRef.complete();
      }
    }));
  }
  /**
   * Check if request is silent.
   * @param req request
   */
  checkUrl(req) {
    const url = req.url.toLowerCase();
    if (this._config.matcher && this._config.silentApis?.length) {
      return checkForMatcher(url, this._config.matcher) && checkForSilentApis(url, this._config.silentApis);
    }
    if (this._config.silentApis?.length) {
      return checkForSilentApis(url, this._config.silentApis);
    }
    if (this._config.matcher) {
      return checkForMatcher(url, this._config.matcher);
    }
    return false;
  }
}
NgProgressInterceptor.ɵfac = function NgProgressInterceptor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgProgressInterceptor)(i0.ɵɵinject(i1.NgProgress), i0.ɵɵinject(NG_PROGRESS_HTTP_CONFIG, 8));
};
NgProgressInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgProgressInterceptor,
  factory: NgProgressInterceptor.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.NgProgress
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [NG_PROGRESS_HTTP_CONFIG]
      }]
    }];
  }, null);
})();
function checkForSilentApis(url, silentApis) {
  return !!silentApis.find(u => url.includes(u.toLowerCase()));
}
function checkForMatcher(url, matcher) {
  return !!url.match(matcher);
}
class NgProgressHttpModule {
  static withConfig(config) {
    return {
      ngModule: NgProgressHttpModule,
      providers: [{
        provide: NG_PROGRESS_HTTP_CONFIG,
        useValue: config
      }]
    };
  }
}
NgProgressHttpModule.ɵfac = function NgProgressHttpModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgProgressHttpModule)();
};
NgProgressHttpModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgProgressHttpModule
});
NgProgressHttpModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: NgProgressInterceptor,
    multi: true
  }]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressHttpModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: NgProgressInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NG_PROGRESS_HTTP_CONFIG, NgProgressHttpModule, NgProgressInterceptor };
