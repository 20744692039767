import { Menu, MenuService } from '../bootstrap/menu.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { SettingsService } from '../bootstrap/settings.service';


@Injectable( {
    providedIn: 'root',
} )
export class AuthGuard implements CanActivate, CanActivateChild {
    private allowedMenu: Menu[];


    constructor( private router: Router, private menu: MenuService, private settingsService: SettingsService, ) {
        this.menu.getAll().subscribe( ms => this.allowedMenu = ms );
    }

    // lazy loading
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canLoad( route: Route, segments: UrlSegment[] ): boolean {
        return this.checkAuth();
    }

    // route
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        return this.checkAuth();
    }

    // all children route
    canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        return childRoute.children.length === 0 ? this.process( state.url ) : true;
    }

    private process( url: string ): boolean {

        if( !this.checkAuth() ) {
            return false;
        }
        return this.checkPolicies( url );
    }

    private checkPolicies( url: string ) {
        const segment = this.router.parseUrl( url ).root.children.primary.segments.map( s => s.path );
        if( segment.length === 0 || segment[ 0 ] === 'landing' ) {
            return true;
        }
        let possibleMenu: Array<Menu> = this.allowedMenu;
        for( const item of segment ) {
            if( !possibleMenu || possibleMenu.length === 0 ) {
                return false;
            }
            const pointed = possibleMenu.filter( m => m.route === item );
            if( pointed.length === 0 ) {
                return false;
            }
            if( pointed[ 0 ].tail === true ) {
                return true;
            }
            possibleMenu = pointed[ 0 ].children;
        }

        return true;
    }

    private checkAuth() {
        if( !this.settingsService.isUserAuthenticated() ) {
            this.settingsService.logout();
            return false;
        }
        return true;
    }

}
