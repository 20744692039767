import { combineLatest, Subject } from 'rxjs';
import { StepService } from '../../step.service';
import { CustomFieldTypeComponent } from '../custom-field-type/custom-field-type.component';
import { CandidateService } from './candidate.service';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import { Config, MapDrawConfigService, } from '@shared/components/osm-draw-map/map-draw-config-service.service';
import { OsmDrawMapComponent } from '@shared/components/osm-draw-map/osm-draw-map.component';
import { ToastrService } from 'ngx-toastr';
import { FeatureGroup, latLng, LatLngTuple } from 'leaflet';
import { StepsCollectionService } from '../../../steps-collection.service';
import { MtxGridColumn, MtxGridColumnButton } from '@ng-matero/extensions/grid';

@Component( {
    selector: 'wnd-candidate-edit',
    templateUrl: './candidate-edit.component.html',
    styleUrls: [ './candidate-edit.component.scss' ],
    providers: [ CandidateService ],
    encapsulation: ViewEncapsulation.None,
} )
export class CandidateEditComponent extends CustomFieldTypeComponent implements OnInit {
    show = false;
    mapTouched = false;
    panelOpenState = false;

    editButton: MtxGridColumnButton = {
        type: 'icon', icon: 'edit', click: ( record ) => this._editCandidate( record ),
    };

    columns: MtxGridColumn[] = [
        { header: '', field: 'owner', type: 'button', buttons: [ this.editButton ] },
        { header: 'Candidato', field: 'description' },
        { header: 'Priorità', field: 'priority' },
        { header: 'RAN', field: 'state' },
        { header: 'SPCM', field: 'accepted' },
    ];

    @ViewChild( 'map' ) mapComp: OsmDrawMapComponent;
    @Input() mapHeight = '50vh';
    @Input() maxWidth = '100%';
    drawConfig: Config;
    private _fixCluster = new Subject<OsmDrawMapComponent>();
    private _firstTime = true;
    currentCenter = { coords: latLng( 41.85, 12.65 ), zoom: 6 };
    // { coords: latLng(41.71145, 13.754883), zoom: 6 };
    // : { coords: L.LatLng; zoom: number }
    stateRange = [ 'OK', 'KO' ];
    disabled = true;

    get candidateList(): any[] {
        return this.candidateService.candidateActivity.candidatesList;
    }

    constructor(
        private mapDrawConfigService: MapDrawConfigService,
        private toastr: ToastrService,
        public candidateService: CandidateService,
        stepService: StepService,
        private cdr: ChangeDetectorRef,
        private stepsCollection: StepsCollectionService,
    ) {
        super( stepService );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.formControl.setErrors( { empty: true } );
        this.candidateService.init( this.field.templateOptions, this._fixCluster );
        this.drawConfig = this.mapDrawConfigService.getConfig( 'new_site' );
        this.show = true;
        combineLatest( [ this.stepService.stepSavedEvent, this.stepsCollection.activityChange ] ).subscribe( () => this._refreshDisabledState() );
    }

    private _refreshDisabledState() {
        this.disabled =
            ( this.stepService.step.isCommited && this.field.templateOptions.disableIfCommited ) || this.stepsCollection.activity.stato === 'Aborted';
        this.candidateService.candidateActivity.disableInput = this.disabled;
        this._updateLDrawControlPresence();
        this.cdr.detectChanges();
    }

    private _updateLDrawControlPresence() {
        const copy = Object.assign( {}, this.drawConfig );
        copy.showControls = !this.disabled;
        this.drawConfig = copy;
    }

    private _editCandidate( record ) {
        const candidateBox = this.candidateService.candidateActivity.candidatesBoxes.find(
            cb => cb.uniqueId === record.uniqueId,
        );
        if( candidateBox ) {
            candidateBox.openPopUp().subscribe( saved => {
                if( saved ) {
                    this.candidateService.candidateActivity.refreshOnMap();
                }
                this.cdr.detectChanges();
            } );
        }
    }

    menuMapOpened() {


        this._refreshDisabledState();
        if( this._firstTime ) {
            this.candidateService.candidateActivity.candidatesReady.subscribe( () => {
                this.mapComp.fixSize();
            } );
            this.candidateService.candidateActivity.validationTrg.subscribe( err => {
                if( err !== 0 ) {
                    this.formControl.setValue(
                        this.candidateService.candidateActivity.candidatesBoxes.map( b => b.candidate ),
                    );
                    this.formControl.setErrors( err );
                }
                setTimeout( () => this._fixCluster.next( this.mapComp ) );
            } );
            this._firstTime = false;
        }
        this.panelOpenState = !this.panelOpenState;
        this.mapComp.fixSize();

        this.mapTouched = true;
    }

    onMapChangeMode( event: any ) {
        if( ( event.type as string ).indexOf( 'start' ) > -1 ) {
            this.candidateService.candidateActivity.disableAllClick();
        } else {
            if( ( event.type as string ).indexOf( 'edit' ) > -1 ) {
                this.candidateService.candidateActivity.refreshOnMap();
            }
            this.candidateService.candidateActivity.enableAllClick();
        }
    }

    drawItemGroupChange( event: { type: string; group: FeatureGroup } ) {
        console.log( 'Candidate edit drawItemGroupChange', event );
        this.candidateService.candidateActivity.featureGroup = event.group;
    }

    mapClosed() {
    }

    mapOpened() {
    }

    get mapWidthPercentage(): number {
        if( this.candidateService.candidateActivity.candidatesList ) {
            return this.candidateService.candidateActivity.candidatesList.length === 0 ? 100 : 73;
        } else {
            return 100;
        }
    }

    onCommit() {
        return this.candidateService.candidateActivity.post();
    }

    onDraftSaving() {
        return this.candidateService.candidateActivity.post();
    }

    defaultLabel() {
        return 'Inserire candidati';
    }

    calculateCenter( candidatesList: any[] ) {

        if( candidatesList.length > 0 ) {
            const listaCoord: LatLngTuple[] = candidatesList.map( ( c ) => {
                return c.geometry?.coordinates ? [ c.geometry.coordinates[ 1 ], c.geometry.coordinates[ 0 ] ] : [ c.locazione.latitude, c.locazione.longitude ];
            } );
            this.mapComp.map.fitBounds( listaCoord );
        } else {
            //candidato/i NON presenti, uso la searchArea (poligono o cerchio)
            const searchAreaStep = this.stepsCollection.steps.filter( s => s.data.searchArea );
            if( searchAreaStep?.length > 0 ) {
                //console.log("passa di qui ", searchAreaStep);
                if( searchAreaStep[ 0 ].data.searchArea.group.features?.length > 0 ) {
                    if( searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ]?.geometry?.type === 'Polygon' ) {
                        if( searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].bbox ) {
                            //console.log("presente bbox", searchAreaStep[0].data.searchArea.group.features[0].bbox);
                            this.mapComp.map.fitBounds( [
                                [ searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].bbox[ 1 ], searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].bbox[ 0 ] ],
                                [ searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].bbox[ 3 ], searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].bbox[ 2 ] ],
                            ] );
                            //this.mapComp.map.fitBounds(this.searchAreaImpl.group.features[0].bbox);
                        } else {
                            //poligono
                            const multipolygon = window.L.geoJSON( searchAreaStep[ 0 ].data.searchArea.group );
                            //multipolygon.addTo(this.mapComp.map);
                            this.mapComp.map.fitBounds( multipolygon.getBounds() );
                        }
                    }
                    if( searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ]?.geometry?.type === 'Point' ) {

                        const lat = searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].geometry.coordinates[ 1 ];
                        const lng = searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].geometry.coordinates[ 0 ];
                        //this.mapComp.map.setView(latLng(lat, lng), 16);
                        const radius = searchAreaStep[ 0 ].data.searchArea.group.features[ 0 ].properties.radius;
                        const bounds = latLng( lat, lng ).toBounds( radius );
                        //console.log("bounds",bounds);
                        this.mapComp.map.panTo( latLng( lat, lng ) ).fitBounds( bounds );

                    }
                } else {
                    //uso il controid dell`Activity
                    const lat = this.candidateService.candidateActivity.activity.centroid.coordinates[ 1 ];
                    const lng = this.candidateService.candidateActivity.activity.centroid.coordinates[ 0 ];
                    this.mapComp.map.setView( latLng( lat, lng ), 12 );
                }

            } else {
                //uso il controid dell`Activity
                const lat = this.candidateService.candidateActivity.activity.centroid.coordinates[ 1 ];
                const lng = this.candidateService.candidateActivity.activity.centroid.coordinates[ 0 ];
                this.mapComp.map.setView( latLng( lat, lng ), 12 );
            }
        }
    }


    mapExpanded() {
        this.calculateCenter( this.candidateService.candidateActivity.candidatesList );
    }
}
