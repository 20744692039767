<div class='title-wrapper mt' *ngIf='plans?.length > 0'>
    <div class='title'>
        <p>Interventi pianificati</p>
    </div>
</div>
<div class='plans' fxLayout='row wrap' fxLayoutGap="5px">
    <div *ngFor='let plan of plans'>
        <ng-container *ngIf="plan.technology !== '5G'">{{ plan.technology }} </ng-container>
        {{ plan.driver }} {{ plan.type }}{{"@"}}{{ plan.frequency }}
    </div>
</div>
