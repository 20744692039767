import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
const _c0 = ["*"];
const _c1 = (a0, a1) => ({
  "mtx-progress-fill-striped": a0,
  "mtx-progress-fill-animated": a1
});
const _c2 = (a0, a1) => ({
  "width.%": a0,
  "background-color": a1
});
class MtxProgressComponent {
  constructor() {
    /** The progress's type. Can be `default`, `info`, `success`, `warning` or `danger`. */
    this.type = 'info';
    /** The value of the progress. */
    this.value = 0;
    this._striped = false;
    this._animate = false;
  }
  /** Whether to apply the striped class. */
  get striped() {
    return this._striped;
  }
  set striped(value) {
    this._striped = coerceBooleanProperty(value);
  }
  /** Whether to apply the animated class. */
  get animate() {
    return this._animate;
  }
  set animate(value) {
    this._animate = coerceBooleanProperty(value);
  }
}
/** @nocollapse */
MtxProgressComponent.ɵfac = function MtxProgressComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxProgressComponent)();
};
/** @nocollapse */
MtxProgressComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MtxProgressComponent,
  selectors: [["mtx-progress"]],
  hostAttrs: [1, "mtx-progress"],
  hostVars: 4,
  hostBindings: function MtxProgressComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("height", ctx.height)("background-color", ctx.background);
    }
  },
  inputs: {
    type: "type",
    value: "value",
    height: "height",
    color: "color",
    foreground: "foreground",
    background: "background",
    striped: "striped",
    animate: "animate"
  },
  exportAs: ["mtxProgress"],
  ngContentSelectors: _c0,
  decls: 2,
  vars: 10,
  consts: [["role", "progress-fill", 3, "ngClass", "ngStyle"]],
  template: function MtxProgressComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap("mtx-progress-fill mtx-progress-fill-" + ctx.type);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c1, ctx.striped, ctx.animate))("ngStyle", i0.ɵɵpureFunction2(7, _c2, ctx.value, ctx.foreground));
    }
  },
  dependencies: [i1.NgClass, i1.NgStyle],
  styles: [".mtx-progress{display:flex;height:1rem;margin:8px 0;overflow:hidden;font-size:.75rem;border-radius:.25rem}.mtx-progress-fill{display:flex;flex-direction:column;justify-content:center;text-align:center;transition:width .6s ease}.mtx-progress-fill-striped{background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);background-size:1rem 1rem}.mtx-progress-fill-animated{animation:mtx-progress-fill-stripes 1s linear infinite}@media (prefers-reduced-motion: reduce){.mtx-progress-fill-animated{animation:none}}@keyframes mtx-progress-fill-stripes{0%{background-position:1rem 0}to{background-position:0 0}}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxProgressComponent, [{
    type: Component,
    args: [{
      selector: 'mtx-progress',
      exportAs: 'mtxProgress',
      host: {
        'class': 'mtx-progress',
        '[style.height]': 'height',
        '[style.backgroundColor]': 'background'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div [class]=\"'mtx-progress-fill mtx-progress-fill-' + type\"\n     [ngClass]=\"{'mtx-progress-fill-striped': striped, 'mtx-progress-fill-animated': animate}\"\n     [ngStyle]=\"{ 'width.%': value, 'background-color': foreground }\"\n     role=\"progress-fill\">\n  <ng-content></ng-content>\n</div>\n",
      styles: [".mtx-progress{display:flex;height:1rem;margin:8px 0;overflow:hidden;font-size:.75rem;border-radius:.25rem}.mtx-progress-fill{display:flex;flex-direction:column;justify-content:center;text-align:center;transition:width .6s ease}.mtx-progress-fill-striped{background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);background-size:1rem 1rem}.mtx-progress-fill-animated{animation:mtx-progress-fill-stripes 1s linear infinite}@media (prefers-reduced-motion: reduce){.mtx-progress-fill-animated{animation:none}}@keyframes mtx-progress-fill-stripes{0%{background-position:1rem 0}to{background-position:0 0}}\n"]
    }]
  }], null, {
    type: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    foreground: [{
      type: Input
    }],
    background: [{
      type: Input
    }],
    striped: [{
      type: Input
    }],
    animate: [{
      type: Input
    }]
  });
})();
class MtxProgressModule {}
/** @nocollapse */
MtxProgressModule.ɵfac = function MtxProgressModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxProgressModule)();
};
/** @nocollapse */
MtxProgressModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxProgressModule
});
/** @nocollapse */
MtxProgressModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxProgressModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [MtxProgressComponent],
      declarations: [MtxProgressComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MtxProgressComponent, MtxProgressModule };
