import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, forwardRef, NgModule } from '@angular/core';
import * as i2 from 'ngx-color';
import { isValidHex, ColorWrap, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule } from 'ngx-color';
import { TinyColor } from '@ctrl/tinycolor';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const _c0 = (a0, a1) => ({
  input: a0,
  label: a1
});
function ChromeFieldsComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11)(1, "color-editable-input", 12);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_2_Template_color_editable_input_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(3, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.hex);
  }
}
function ChromeFieldsComponent_ng_template_3_color_editable_input_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "color-editable-input", 17);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_3_color_editable_input_7_Template_color_editable_input_onChange_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(4, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.rgb.a)("arrowOffset", 0.01);
  }
}
function ChromeFieldsComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11)(1, "color-editable-input", 13);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_3_Template_color_editable_input_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(2, "div", 11)(3, "color-editable-input", 14);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_3_Template_color_editable_input_onChange_3_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(4, "div", 11)(5, "color-editable-input", 15);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_3_Template_color_editable_input_onChange_5_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(6, "div", 11);
    i0.ɵɵtemplate(7, ChromeFieldsComponent_ng_template_3_color_editable_input_7_Template, 1, 7, "color-editable-input", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(10, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.rgb.r);
    i0.ɵɵadvance(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(13, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.rgb.g);
    i0.ɵɵadvance(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(16, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.rgb.b);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r2.disableAlpha);
  }
}
function ChromeFieldsComponent_ng_template_4_color_editable_input_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "color-editable-input", 17);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_4_color_editable_input_7_Template_color_editable_input_onChange_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(4, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.hsl.a)("arrowOffset", 0.01);
  }
}
function ChromeFieldsComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11)(1, "color-editable-input", 18);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_4_Template_color_editable_input_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(2, "div", 11)(3, "color-editable-input", 19);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_4_Template_color_editable_input_onChange_3_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(4, "div", 11)(5, "color-editable-input", 20);
    i0.ɵɵlistener("onChange", function ChromeFieldsComponent_ng_template_4_Template_color_editable_input_onChange_5_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(6, "div", 11);
    i0.ɵɵtemplate(7, ChromeFieldsComponent_ng_template_4_color_editable_input_7_Template, 1, 7, "color-editable-input", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(10, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.round(ctx_r2.hsl.h));
    i0.ɵɵadvance(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(13, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.round(ctx_r2.hsl.s * 100) + "%");
    i0.ɵɵadvance(2);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction2(16, _c0, ctx_r2.input, ctx_r2.label));
    i0.ɵɵproperty("value", ctx_r2.round(ctx_r2.hsl.l * 100) + "%");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r2.disableAlpha);
  }
}
function ChromeComponent_div_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12)(1, "color-alpha", 13);
    i0.ɵɵlistener("onChange", function ChromeComponent_div_12_Template_color_alpha_onChange_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleValueChange($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("radius", 2)("rgb", ctx_r1.rgb)("hsl", ctx_r1.hsl)("pointer", ctx_r1.pointer);
  }
}
class ChromeFieldsComponent {
  constructor() {
    this.onChange = new EventEmitter();
    this.view = '';
    this.input = {
      fontSize: '11px',
      color: '#333',
      width: '100%',
      borderRadius: '2px',
      border: 'none',
      boxShadow: 'inset 0 0 0 1px #dadada',
      height: '21px',
      'text-align': 'center'
    };
    this.label = {
      'text-transform': 'uppercase',
      fontSize: '11px',
      'line-height': '11px',
      color: '#969696',
      'text-align': 'center',
      display: 'block',
      marginTop: '12px'
    };
  }
  ngOnInit() {
    if (this.hsl.a === 1 && this.view !== 'hex') {
      this.view = 'hex';
    } else if (this.view !== 'rgb' && this.view !== 'hsl') {
      this.view = 'rgb';
    }
  }
  toggleViews() {
    if (this.view === 'hex') {
      this.view = 'rgb';
    } else if (this.view === 'rgb') {
      this.view = 'hsl';
    } else if (this.view === 'hsl') {
      if (this.hsl.a === 1) {
        this.view = 'hex';
      } else {
        this.view = 'rgb';
      }
    }
  }
  round(value) {
    return Math.round(value);
  }
  handleChange({
    data,
    $event
  }) {
    if (data.hex) {
      if (isValidHex(data.hex)) {
        const color = new TinyColor(data.hex);
        this.onChange.emit({
          data: {
            hex: this.disableAlpha ? color.toHex() : color.toHex8(),
            source: 'hex'
          },
          $event
        });
      }
    } else if (data.r || data.g || data.b) {
      this.onChange.emit({
        data: {
          r: data.r || this.rgb.r,
          g: data.g || this.rgb.g,
          b: data.b || this.rgb.b,
          source: 'rgb'
        },
        $event
      });
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0;
      } else if (data.a > 1) {
        data.a = 1;
      }
      if (this.disableAlpha) {
        data.a = 1;
      }
      this.onChange.emit({
        data: {
          h: this.hsl.h,
          s: this.hsl.s,
          l: this.hsl.l,
          a: Math.round(data.a * 100) / 100,
          source: 'rgb'
        },
        $event
      });
    } else if (data.h || data.s || data.l) {
      const s = data.s && data.s.replace('%', '');
      const l = data.l && data.l.replace('%', '');
      this.onChange.emit({
        data: {
          h: data.h || this.hsl.h,
          s: Number(s || this.hsl.s),
          l: Number(l || this.hsl.l),
          source: 'hsl'
        },
        $event
      });
    }
  }
}
ChromeFieldsComponent.ɵfac = function ChromeFieldsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ChromeFieldsComponent)();
};
ChromeFieldsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ChromeFieldsComponent,
  selectors: [["color-chrome-fields"]],
  inputs: {
    disableAlpha: "disableAlpha",
    hsl: "hsl",
    rgb: "rgb",
    hex: "hex"
  },
  outputs: {
    onChange: "onChange"
  },
  decls: 13,
  vars: 3,
  consts: [["icon", ""], ["iconUp", ""], ["iconDown", ""], [1, "chrome-wrap"], [1, "chrome-fields"], [3, "ngIf"], [1, "chrome-toggle"], [1, "chrome-icon", 3, "click"], ["viewBox", "0 0 24 24", 1, "chrome-toggle-svg"], ["fill", "#333", "d", "M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z"], ["fill", "#333", "d", "M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15Z"], [1, "chrome-field"], ["label", "hex", 3, "onChange", "value"], ["label", "r", 3, "onChange", "value"], ["label", "g", 3, "onChange", "value"], ["label", "b", 3, "onChange", "value"], ["label", "a", 3, "style", "value", "arrowOffset", "onChange", 4, "ngIf"], ["label", "a", 3, "onChange", "value", "arrowOffset"], ["label", "h", 3, "onChange", "value"], ["label", "s", 3, "onChange", "value"], ["label", "l", 3, "onChange", "value"]],
  template: function ChromeFieldsComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
      i0.ɵɵtemplate(2, ChromeFieldsComponent_ng_template_2_Template, 2, 6, "ng-template", 5)(3, ChromeFieldsComponent_ng_template_3_Template, 8, 19, "ng-template", 5)(4, ChromeFieldsComponent_ng_template_4_Template, 8, 19, "ng-template", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 6)(6, "div", 7, 0);
      i0.ɵɵlistener("click", function ChromeFieldsComponent_Template_div_click_6_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.toggleViews());
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(8, "svg", 8);
      i0.ɵɵelement(9, "path", 9, 1)(11, "path", 10, 2);
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.view === "hex");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.view === "rgb");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.view === "hsl");
    }
  },
  dependencies: [i1.NgIf, i2.EditableInputComponent],
  styles: [".chrome-wrap[_ngcontent-%COMP%]{padding-top:16px;display:flex}.chrome-fields[_ngcontent-%COMP%]{flex:1;display:flex;margin-left:-6px}.chrome-field[_ngcontent-%COMP%]{padding-left:6px;width:100%}.chrome-toggle[_ngcontent-%COMP%]{width:32px;text-align:right;position:relative}.chrome-icon[_ngcontent-%COMP%]{margin-right:-4px;margin-top:12px;cursor:pointer;position:relative}.chrome-toggle-svg[_ngcontent-%COMP%]{width:24px;height:24px;border:1px transparent solid;border-radius:5px}.chrome-toggle-svg[_ngcontent-%COMP%]:hover{background:#eee}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChromeFieldsComponent, [{
    type: Component,
    args: [{
      selector: 'color-chrome-fields',
      template: `
    <div class="chrome-wrap">
      <div class="chrome-fields">
        <ng-template [ngIf]="view === 'hex'">
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="hex" [value]="hex"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
        </ng-template>
        <ng-template [ngIf]="view === 'rgb'">
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="r" [value]="rgb.r"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="g" [value]="rgb.g"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="b" [value]="rgb.b"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input *ngIf="!disableAlpha"
              [style]="{ input: input, label: label }"
              label="a" [value]="rgb.a"
              [arrowOffset]="0.01"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
        </ng-template>
        <ng-template [ngIf]="view === 'hsl'">
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="h"
              [value]="round(hsl.h)"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="s" [value]="round(hsl.s * 100) + '%'"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input
              [style]="{ input: input, label: label }"
              label="l" [value]="round(hsl.l * 100) + '%'"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
          <div class="chrome-field">
            <color-editable-input *ngIf="!disableAlpha"
              [style]="{ input: input, label: label }"
              label="a" [value]="hsl.a"
              [arrowOffset]="0.01"
              (onChange)="handleChange($event)"
            ></color-editable-input>
          </div>
        </ng-template>
      </div>

      <div class="chrome-toggle">
        <div class="chrome-icon" (click)="toggleViews()" #icon>
          <svg class="chrome-toggle-svg" viewBox="0 0 24 24">
            <path #iconUp fill="#333"
              d="M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z"
            />
            <path #iconDown fill="#333"
              d="M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15Z"
            />
          </svg>
        </div>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      styles: [".chrome-wrap{padding-top:16px;display:flex}.chrome-fields{flex:1;display:flex;margin-left:-6px}.chrome-field{padding-left:6px;width:100%}.chrome-toggle{width:32px;text-align:right;position:relative}.chrome-icon{margin-right:-4px;margin-top:12px;cursor:pointer;position:relative}.chrome-toggle-svg{width:24px;height:24px;border:1px transparent solid;border-radius:5px}.chrome-toggle-svg:hover{background:#eee}\n"]
    }]
  }], null, {
    disableAlpha: [{
      type: Input
    }],
    hsl: [{
      type: Input
    }],
    rgb: [{
      type: Input
    }],
    hex: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class ChromeComponent extends ColorWrap {
  constructor() {
    super();
    /** Remove alpha slider and options from picker */
    this.disableAlpha = false;
    this.circle = {
      width: '12px',
      height: '12px',
      borderRadius: '6px',
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 1px inset',
      transform: 'translate(-6px, -8px)'
    };
    this.pointer = {
      width: '12px',
      height: '12px',
      borderRadius: '6px',
      transform: 'translate(-6px, -2px)',
      backgroundColor: 'rgb(248, 248, 248)',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)'
    };
  }
  afterValidChange() {
    const alpha = this.disableAlpha ? 1 : this.rgb.a;
    this.activeBackground = `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, ${alpha})`;
  }
  handleValueChange({
    data,
    $event
  }) {
    this.handleChange(data, $event);
  }
}
ChromeComponent.ɵfac = function ChromeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ChromeComponent)();
};
ChromeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ChromeComponent,
  selectors: [["color-chrome"]],
  inputs: {
    disableAlpha: "disableAlpha"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChromeComponent),
    multi: true
  }, {
    provide: ColorWrap,
    useExisting: forwardRef(() => ChromeComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 14,
  vars: 16,
  consts: [[1, "saturation"], [3, "onChange", "hsl", "hsv", "circle"], [1, "chrome-body"], [1, "chrome-controls"], [1, "chrome-color"], [1, "chrome-swatch"], [1, "chrome-active"], [1, "chrome-toggles"], [1, "chrome-hue"], [3, "onChange", "radius", "hsl", "pointer"], ["class", "chrome-alpha", 4, "ngIf"], [3, "onChange", "rgb", "hsl", "hex", "disableAlpha"], [1, "chrome-alpha"], [3, "onChange", "radius", "rgb", "hsl", "pointer"]],
  template: function ChromeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div")(1, "div", 0)(2, "color-saturation", 1);
      i0.ɵɵlistener("onChange", function ChromeComponent_Template_color_saturation_onChange_2_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "div", 4)(6, "div", 5);
      i0.ɵɵelement(7, "div", 6)(8, "color-checkboard");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(9, "div", 7)(10, "div", 8)(11, "color-hue", 9);
      i0.ɵɵlistener("onChange", function ChromeComponent_Template_color_hue_onChange_11_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(12, ChromeComponent_div_12_Template, 2, 4, "div", 10);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(13, "color-chrome-fields", 11);
      i0.ɵɵlistener("onChange", function ChromeComponent_Template_color_chrome_fields_onChange_13_listener($event) {
        return ctx.handleValueChange($event);
      });
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("chrome-picker ", ctx.className, "");
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("hsl", ctx.hsl)("hsv", ctx.hsv)("circle", ctx.circle);
      i0.ɵɵadvance(5);
      i0.ɵɵstyleProp("background", ctx.activeBackground);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("radius", 2)("hsl", ctx.hsl)("pointer", ctx.pointer);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.disableAlpha);
      i0.ɵɵadvance();
      i0.ɵɵproperty("rgb", ctx.rgb)("hsl", ctx.hsl)("hex", ctx.hex)("disableAlpha", ctx.disableAlpha);
    }
  },
  dependencies: () => [i1.NgIf, i2.AlphaComponent, i2.CheckboardComponent, i2.HueComponent, i2.SaturationComponent, ChromeFieldsComponent],
  styles: [".chrome-picker[_ngcontent-%COMP%]{background:#fff;border-radius:2px;box-shadow:0 0 2px #0000004d,0 4px 8px #0000004d;box-sizing:initial;width:225px;font-family:Menlo}.chrome-controls[_ngcontent-%COMP%]{display:flex}.chrome-color[_ngcontent-%COMP%]{width:42px}.chrome-body[_ngcontent-%COMP%]{padding:14px 14px 12px}.chrome-active[_ngcontent-%COMP%]{position:absolute;top:0;bottom:0;left:0;right:0;border-radius:20px;box-shadow:inset 0 0 0 1px #0000001a;z-index:2}.chrome-swatch[_ngcontent-%COMP%]{width:28px;height:28px;border-radius:15px;position:relative;overflow:hidden}.saturation[_ngcontent-%COMP%]{width:100%;padding-bottom:55%;position:relative;border-radius:2px 2px 0 0;overflow:hidden}.chrome-toggles[_ngcontent-%COMP%]{flex:1}.chrome-hue[_ngcontent-%COMP%]{height:10px;position:relative;margin-bottom:8px}.chrome-alpha[_ngcontent-%COMP%]{height:10px;position:relative}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChromeComponent, [{
    type: Component,
    args: [{
      selector: 'color-chrome',
      template: `
  <div class="chrome-picker {{ className }}">
    <div class="saturation">
      <color-saturation
        [hsl]="hsl"
        [hsv]="hsv"
        [circle]="circle"
        (onChange)="handleValueChange($event)"
      ></color-saturation>
    </div>
    <div class="chrome-body">
      <div class="chrome-controls">
        <div class="chrome-color">
          <div class="chrome-swatch">
            <div class="chrome-active"
              [style.background]="activeBackground"
            ></div>
            <color-checkboard></color-checkboard>
          </div>
        </div>
        <div class="chrome-toggles">
          <div class="chrome-hue">
            <color-hue
              [radius]="2"
              [hsl]="hsl"
              [pointer]="pointer"
              (onChange)="handleValueChange($event)"
            ></color-hue>
          </div>
          <div class="chrome-alpha" *ngIf="!disableAlpha">
            <color-alpha
              [radius]="2" [rgb]="rgb" [hsl]="hsl"
              [pointer]="pointer" (onChange)="handleValueChange($event)"
            ></color-alpha>
          </div>
        </div>
      </div>
      <color-chrome-fields
        [rgb]="rgb" [hsl]="hsl" [hex]="hex"
        [disableAlpha]="disableAlpha"
        (onChange)="handleValueChange($event)"
      ></color-chrome-fields>
    </div>
  </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ChromeComponent),
        multi: true
      }, {
        provide: ColorWrap,
        useExisting: forwardRef(() => ChromeComponent)
      }],
      styles: [".chrome-picker{background:#fff;border-radius:2px;box-shadow:0 0 2px #0000004d,0 4px 8px #0000004d;box-sizing:initial;width:225px;font-family:Menlo}.chrome-controls{display:flex}.chrome-color{width:42px}.chrome-body{padding:14px 14px 12px}.chrome-active{position:absolute;top:0;bottom:0;left:0;right:0;border-radius:20px;box-shadow:inset 0 0 0 1px #0000001a;z-index:2}.chrome-swatch{width:28px;height:28px;border-radius:15px;position:relative;overflow:hidden}.saturation{width:100%;padding-bottom:55%;position:relative;border-radius:2px 2px 0 0;overflow:hidden}.chrome-toggles{flex:1}.chrome-hue{height:10px;position:relative;margin-bottom:8px}.chrome-alpha{height:10px;position:relative}\n"]
    }]
  }], function () {
    return [];
  }, {
    disableAlpha: [{
      type: Input
    }]
  });
})();
class ColorChromeModule {}
ColorChromeModule.ɵfac = function ColorChromeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorChromeModule)();
};
ColorChromeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorChromeModule
});
ColorChromeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorChromeModule, [{
    type: NgModule,
    args: [{
      declarations: [ChromeComponent, ChromeFieldsComponent],
      exports: [ChromeComponent, ChromeFieldsComponent],
      imports: [CommonModule, AlphaModule, CheckboardModule, EditableInputModule, HueModule, SaturationModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ChromeComponent, ChromeFieldsComponent, ColorChromeModule };
