/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return */
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Config, MapDrawConfigService } from '@shared/components/osm-draw-map/map-draw-config-service.service';
import { OsmDrawMapComponent } from '@shared/components/osm-draw-map/osm-draw-map.component';
import { FeatureGroup, latLng } from 'leaflet';

import { StepService } from '../../step.service';
import { CustomFieldTypeComponent } from '../custom-field-type/custom-field-type.component';
import { SearchAreaImpl } from './search-area';
import { SearchAreaBackEndService } from './search-area-back-end.service';
import { map, tap } from 'rxjs/operators';
import { StepsCollectionService } from '../../../steps-collection.service';
import { merge } from 'rxjs';

@Component( {
    selector: 'wnd-area-picker',
    templateUrl: './area-picker.component.html',
    styleUrls: [ './area-picker.component.scss' ],
    providers: [ SearchAreaBackEndService ],
} )
export class AreaPickerComponent extends CustomFieldTypeComponent implements OnInit {
    @Input() mapHeight = '50vh';
    @Input() maxWidth = '100%';
    @ViewChild( 'map' ) mapComp: OsmDrawMapComponent;

    show = false;
    mapTouched = false;
    panelOpenState = false;
    searchAreaImpl = new SearchAreaImpl();
    currentCenter = { coords: latLng( 41.85, 12.65 ), zoom: 6 };
    disabled = true;

    drawConfig: Config;

    constructor(
        private searchAreaBackEndService: SearchAreaBackEndService,
        stepService: StepService,
        private mapDrawConfigService: MapDrawConfigService,
        private stepsCollection: StepsCollectionService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef
    ) {
        super( stepService );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.drawConfig = this.mapDrawConfigService.getConfig( 'search_area' );
        this.init();

        this.searchAreaImpl.statusChange.subscribe( val => {
            if( val !== null ) {
                this._validate();
            }
        } );

        this._refreshDisabledState();
        merge( this.stepService.stepSavedEvent, this.stepsCollection.activityChange ).subscribe( () =>
            this._refreshDisabledState()
        );
        this.show = true;
    }

    drawItemGroupChange( event: { type: string; group: FeatureGroup } ) {
        if( event.group.getLayers().length > 1 ) {
            event.group
                .getLayers()
                .filter( ( _l, i ) => i !== 0 )
                .forEach( l => event.group.removeLayer( l ) );
            this.toastr.warning( this.field.templateOptions.map.errorMessage );
        }
        this.searchAreaImpl.featureGroup = event.group;
    }

    menuMapOpened() {
        this.panelOpenState = !this.panelOpenState;
        this.mapComp.fixSize();
        this.mapTouched = true;
    }

    mapClosed() {
    }

    mapOpened() {
    }

    onCommit() {
        return this.save().pipe( map( () => {
        } ) );
    }

    onDraftSaving() {
        return this.save().pipe( map( () => {
        } ) );
    }

    defaultLabel() {
        return 'Inserire area di ricerca';
    }

    save() {
        if( this.searchAreaImpl.id ) {
            return this.searchAreaBackEndService.put( this.searchAreaImpl.searchArea );
        } else {
            return this.searchAreaBackEndService.post( this.searchAreaImpl.searchArea ).pipe(
                tap( a => {
                    this.searchAreaImpl.id = a.id;
                } )
            );
        }
    }

    init() {
        this.searchAreaBackEndService.getByActivity( this.stepService.step.idActivity ).subscribe( {
            next: area => {
                this.searchAreaImpl.searchArea = area;
            },
            error: () => ( this.searchAreaImpl.idActivity = this.stepService.step.idActivity )
        } );
    }

    mapExpanded() {
        this._calculateCenter( this._getGeometryFromGroup( this.searchAreaImpl.group ) );
    }

    private _calculateCenter( coordinates: any[] ) {
        if( coordinates ) {
            if( coordinates[ 1 ] ) {
                //cerchio
                const radius = this.searchAreaImpl.group.features[ 0 ].properties.radius;
                const [ lng, lat ] = coordinates;
                const bounds = latLng( lat, lng ).toBounds( radius );
                this.mapComp.map.panTo( latLng( lat, lng ) ).fitBounds( bounds );
            } else {
                if( this.searchAreaImpl.group.features[ 0 ].bbox ) {
                    //console.log("presente bbox",this.searchAreaImpl.group.features[0].bbox);
                    this.mapComp.map.fitBounds( [
                        [
                            this.searchAreaImpl.group.features[ 0 ].bbox[ 1 ],
                            this.searchAreaImpl.group.features[ 0 ].bbox[ 0 ],
                        ],
                        [
                            this.searchAreaImpl.group.features[ 0 ].bbox[ 3 ],
                            this.searchAreaImpl.group.features[ 0 ].bbox[ 2 ],
                        ],
                    ] );
                    //this.mapComp.map.fitBounds(this.searchAreaImpl.group.features[0].bbox);
                } else {
                    //poligono
                    //console.log(this.searchAreaImpl.group);
                    const multipolygon = window.L.geoJSON( this.searchAreaImpl.group );
                    //multipolygon.addTo(this.mapComp.map);
                    this.mapComp.map.fitBounds( multipolygon.getBounds() );
                }
            }
        } else {
            //centroid dell`activity
            const lat = this.stepsCollection.activity.centroid.coordinates[ 1 ];
            const lng = this.stepsCollection.activity.centroid.coordinates[ 0 ];
            this.mapComp.map.setView( latLng( lat, lng ), 12 );
        }
        this.cdr.detectChanges();
    }

    private _getGeometryFromGroup( group: any ) {
        if( group?.features[ 0 ] ) {
            const [ lng, lat ] = group.features[ 0 ].geometry.coordinates;
            return [ lng, lat ];
        } else {
            return null;
        }
    }

    private _refreshDisabledState() {
        this.disabled =
            ( this.stepService.step.isCommited && this.field.templateOptions.disableIfCommited ) ||
            this.stepsCollection.activity?.stato === 'Aborted';
        this._updateLDrawControlPresence();
        this.cdr.detectChanges();
    }

    private _updateLDrawControlPresence() {
        const copy = Object.assign( {}, this.drawConfig );
        copy.showControls = !this.disabled;
        this.drawConfig = copy;
    }

    private _validate() {
        this.formControl.setValue( this.searchAreaImpl.searchArea );
        if( this.searchAreaImpl.featureGroup.getLayers().length === 0 ) {
            this.formControl.setErrors( { noItems: true } );
        } else {
            this.formControl.setErrors( null );
        }
    }

}
