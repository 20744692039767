/* eslint-disable @typescript-eslint/unbound-method */
import { TusUploaderDirective } from './directives/tus-uploader.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialExtensionsModule } from '../material-extensions.module';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { DisableControlDirective } from './directives/disable-control.directive';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { OsmDrawMapComponent } from './components/osm-draw-map/osm-draw-map.component';
import { OsmDrawWrapperComponent } from './components/osm-draw-map/osm-draw-wrapper/osm-draw-wrapper.component';
import { CustomDetailsListComponent } from './components/custom-details-list/custom-details-list.component';
import { CoordsMarkerInputComponent } from './components/osm-draw-map/coords-marker-input/coords-marker-input.component';
import { FormValidators } from '@core/validators/form-validators';
import { LeafletDrawModule } from '@bluehalo/ngx-leaflet-draw';
import { NgxUploaderModule } from 'ngx-uploader';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { AreaPickerComponent } from 'app/routes/nd/lavorazioni/step-edit/custom-field/area-picker/area-picker.component';
import { CommunicationsComponent } from 'app/routes/nd/lavorazioni/step-edit/custom-field/communications/communications.component';
import { CandidateEditComponent } from 'app/routes/nd/lavorazioni/step-edit/custom-field/candidate-edit/candidate-edit.component';
import { SingleFileComponent } from 'app/routes/nd/lavorazioni/step-edit/custom-field/single-file/single-file.component';
import { SingleFileUploaderComponent } from 'app/routes/nd/lavorazioni/step-edit/custom-field/single-file-uploader/single-file-uploader.component';
import { KeyValueDetailsComponent } from './components/key-value-details/key-value-details.component';
import { StickExpansionPanelComponent } from './components/stick-expansion-panel/stick-expansion-panel.component';
import { CollapsableDisplayComponent } from './components/collapsable-display/collapsable-display.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { GeoSyncFieldComponent } from '../routes/nd/lavorazioni/step-edit/custom-field/geo-sync-field/geo-sync-field.component';
import { SuffixWrapperComponent } from '../routes/nd/lavorazioni/step-edit/custom-field/suffix/suffix.wrapper';
import { MapSearchComponent } from './components/osm-draw-map/map-search/map-search.component';
import { GeoSyncComponent } from '@shared/components/geo-sync/geo-sync.component';
import { AutoselectComponent } from './components/autoselect/autoselect.component';
import { WndGridComponent } from './components/wonder-grid/wnd-grid/wnd-grid.component';
import { ColumnHeaderComponent } from './components/wonder-grid/components/column-header/column-header.component';
import { FilterHostComponent } from './components/wonder-grid/components/filter-host/filter-host.component';
import { FilterMenuComponent } from './components/wonder-grid/components/filter-menu/filter-menu.component';
import { TextFilterComponent } from './components/wonder-grid/components/filters/text-filter/text-filter.component';
import { DateFilterComponent } from './components/wonder-grid/components/filters/date-filter/date-filter.component';
import { NumberFilterComponent } from './components/wonder-grid/components/filters/number-filter/number-filter.component';
import { BooleanFilterComponent } from './components/wonder-grid/components/filters/boolean-filter/boolean-filter.component';
import { BaseFilterComponent } from './components/wonder-grid/components/filters/base-filter/base-filter.component';
import { OptionsListComponent } from './components/wonder-grid/components/options-list/options-list.component';
import { FilterMainFieldComponent } from './components/wonder-grid/components/filter-main-field/filter-main-field.component';
import { WndGridRowPanelComponent } from './components/wonder-grid/components/wnd-grid-row-panel/wnd-grid-row-panel.component';
import { ExcelExportComponent } from '@shared/components/wonder-grid/components/excel-export/excel-export.component';
import { BaseChartComponent } from './components/charts/base-chart/base-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { ImportButtonComponent } from './components/import-button/import-button.component';
import { ElementsPlannedComponent } from './components/elements-planned/elements-planned.component';
import { ActiveFrequenciesComponent } from './components/active-frequencies/active-frequencies.component';
import { FluidHeightDirective } from './directives/fluid-height.directive';
import { GridColumnsChooserComponent } from './components/grid-columns-chooser/grid-columns-chooser.component';
import { SiNoPipe } from './pipes/si-no.pipe';
import { ComboTreeComponent } from '@shared/components/combo-tree/combo-tree.component';
import { ZonePipe } from './pipes/zone.pipe';
import { ChipsMultiSelectComponent } from './components/chips-multi-select/chips-multi-select.component';
import { OfficeDetailsDialogComponent } from './components/office-details-dialog/office-details-dialog.component';
import { OperatoreModalComponent } from '@shared/components/operatore-modal/operatore-modal.component';
import { UserSettingsComponent } from '@shared/components/user-settings/user-settings.component';
import { YearSelectorComponent } from './components/year-selector/year-selector.component';
import { StrutturaDetailsDialogComponent } from './components/struttura-details-dialog/struttura-details-dialog.component';
import { StrutturaDetailsComponent } from './components/struttura-details/struttura-details.component';
import { CellPipe } from './pipes/cell.pipe';
import { RequiredPolicyPipe } from './pipes/required-policy.pipe';
import { DynamicColumnsDrawerComponent } from './components/wonder-grid/components/dynamic-columns-drawer/dynamic-columns-drawer.component';
import { InputTypeaheadComponent } from './components/input-typeahead/input-typeahead.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { NgxColorsModule } from 'ngx-colors';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { AnimatedFormComponent } from './components/animated-form/animated-form.component';
import { FormUtilsComponent } from './components/form-utils/form-utils.component';
import { AddReasonDialogComponent } from './components/add-reason-dialog/add-reason-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

const MODULES = [
    MaterialModule,
    MaterialExtensionsModule,
    FlexLayoutModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,

    FormlyMaterialModule,
    ToastrModule,
    TranslateModule,

    FormlyModule.forRoot( {
        types: [
            { name: 'searchArea', component: AreaPickerComponent },
            { name: 'communication', component: CommunicationsComponent },
            { name: 'candidate', component: CandidateEditComponent },
            { name: 'singleFile', component: SingleFileComponent },
            { name: 'singleFileUpload', component: SingleFileUploaderComponent },
            { name: 'geoSync', component: GeoSyncFieldComponent },
            // {
            //   name: 'autocomplete',
            //   component: AutocompleteComponent,
            //   wrappers: ['form-field'],
            // },
        ],
        validators: [
            { name: 'date', validation: FormValidators.ValidDate },
            { name: 'fieldDateMatch', validation: FormValidators.dateValidator },
        ],
        validationMessages: [ { name: 'date', message: FormValidators.DateValidationMessage } ],
        wrappers: [
            { name: 'suffix', component: SuffixWrapperComponent },
        ],
    } ),
    LeafletMarkerClusterModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    NgxUploaderModule,
    LeafletModule,
    LeafletDrawModule,
    NgxColorsModule,
];
const COMPONENTS = [
    ActionMenuComponent,
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent,
    OsmDrawMapComponent,
    CustomDetailsListComponent,
    OsmDrawWrapperComponent,
    CoordsMarkerInputComponent,
    KeyValueDetailsComponent,
    StickExpansionPanelComponent,
    CollapsableDisplayComponent,
    AutocompleteComponent,
    MapSearchComponent,
    GeoSyncComponent,
    AutoselectComponent,
    WndGridComponent,
    ColumnHeaderComponent,
    FilterHostComponent,
    FilterMenuComponent,
    TextFilterComponent,
    BaseFilterComponent,
    OptionsListComponent,
    DateFilterComponent,
    NumberFilterComponent,
    BooleanFilterComponent,
    ExcelExportComponent,
    BaseChartComponent,
    UserSettingsComponent,
    FilterMainFieldComponent,
    WndGridRowPanelComponent,
    ImportButtonComponent,
    ElementsPlannedComponent,
    ActiveFrequenciesComponent,
    FluidHeightDirective,
    GridColumnsChooserComponent,
    ComboTreeComponent,
    ChipsMultiSelectComponent,
    OfficeDetailsDialogComponent,
    OperatoreModalComponent,
    YearSelectorComponent,
    StrutturaDetailsDialogComponent,
    StrutturaDetailsComponent,
    DynamicColumnsDrawerComponent,
    InputTypeaheadComponent,
    AnimatedFormComponent,
    FormUtilsComponent,
    AddReasonDialogComponent,
    ConfirmDialogComponent,
];
const DIRECTIVES = [ DisableControlDirective, TusUploaderDirective ];
const PIPES = [ SafeUrlPipe, ToObservablePipe, CellPipe, RequiredPolicyPipe, SiNoPipe, ZonePipe, DateAgoPipe ];

@NgModule( {
    declarations: [ ...COMPONENTS, ...DIRECTIVES, ...PIPES, ],
    imports: [ CommonModule, FormsModule, RouterModule, ReactiveFormsModule, ...MODULES, NgChartsModule ],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...( MODULES as any[] ),
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
    ],
} )
export class SharedModule {
}
