import * as i1 from '@angular/cdk/overlay';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import * as i4 from '@angular/cdk/a11y';
import { A11yModule } from '@angular/cdk/a11y';
import * as i7 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, TemplateRef, Directive, Inject, Optional, Input, Component, ViewEncapsulation, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import * as i8 from '@ng-matero/extensions/core';
import { MtxPipesModule } from '@ng-matero/extensions/core';
import * as i2 from '@angular/cdk/scrolling';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import * as i6 from '@angular/cdk/layout';
import { Breakpoints } from '@angular/cdk/layout';
import * as i3 from '@angular/cdk/platform';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import * as i5 from '@angular/cdk/bidi';

/**
 * Animations used by MtxTooltip.
 * @docs-private
 */
const _c0 = a0 => ({
  $implicit: a0
});
function TooltipComponent_ng_template_2_ng_template_0_Template(rf, ctx) {}
function TooltipComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TooltipComponent_ng_template_2_ng_template_0_Template, 0, 0, "ng-template", 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.message)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r1.tooltipContext));
  }
}
function TooltipComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate(ctx_r1.message);
  }
}
const mtxTooltipAnimations = {
  /** Animation that transitions a tooltip in and out. */
  tooltipState: trigger('state', [state('initial, void, hidden', style({
    opacity: 0,
    transform: 'scale(0)'
  })), state('visible', style({
    transform: 'scale(1)'
  })), transition('* => visible', animate('200ms cubic-bezier(0, 0, 0.2, 1)', keyframes([style({
    opacity: 0,
    transform: 'scale(0)',
    offset: 0
  }), style({
    opacity: 0.5,
    transform: 'scale(0.99)',
    offset: 0.5
  }), style({
    opacity: 1,
    transform: 'scale(1)',
    offset: 1
  })]))), transition('* => hidden', animate('100ms cubic-bezier(0, 0, 0.2, 1)', style({
    opacity: 0
  })))])
};

/** Time in ms to throttle repositioning after scroll events. */
const SCROLL_THROTTLE_MS = 20;
/** CSS class that will be attached to the overlay panel. */
const TOOLTIP_PANEL_CLASS = 'mtx-tooltip-panel';
/** Options used to bind passive event listeners. */
const passiveListenerOptions = normalizePassiveListenerOptions({
  passive: true
});
/**
 * Time between the user putting the pointer on a tooltip
 * trigger and the long press event being fired.
 */
const LONGPRESS_DELAY = 500;
/**
 * Creates an error to be thrown if the user supplied an invalid tooltip position.
 * @docs-private
 */
function getMtxTooltipInvalidPositionError(position) {
  return Error(`Tooltip position "${position}" is invalid.`);
}
/** Injection token that determines the scroll handling while a tooltip is visible. */
const MTX_TOOLTIP_SCROLL_STRATEGY = new InjectionToken('mtx-tooltip-scroll-strategy');
/** @docs-private */
function MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY(overlay) {
  return () => overlay.scrollStrategies.reposition({
    scrollThrottle: SCROLL_THROTTLE_MS
  });
}
/** @docs-private */
const MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: MTX_TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY
};
/** Injection token to be used to override the default options for `matTooltip`. */
const MTX_TOOLTIP_DEFAULT_OPTIONS = new InjectionToken('mtx-tooltip-default-options', {
  providedIn: 'root',
  factory: MTX_TOOLTIP_DEFAULT_OPTIONS_FACTORY
});
/** @docs-private */
function MTX_TOOLTIP_DEFAULT_OPTIONS_FACTORY() {
  return {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500
  };
}
/**
 * Directive that attaches a material design tooltip to the host element. Animates the showing and
 * hiding of a tooltip provided position (defaults to below the element).
 *
 * https://material.io/design/components/tooltips.html
 */
class MtxTooltip {
  constructor(_overlay, _elementRef, _scrollDispatcher, _viewContainerRef, _ngZone, _platform, _ariaDescriber, _focusMonitor, scrollStrategy, _dir, _defaultOptions) {
    this._overlay = _overlay;
    this._elementRef = _elementRef;
    this._scrollDispatcher = _scrollDispatcher;
    this._viewContainerRef = _viewContainerRef;
    this._ngZone = _ngZone;
    this._platform = _platform;
    this._ariaDescriber = _ariaDescriber;
    this._focusMonitor = _focusMonitor;
    this._dir = _dir;
    this._defaultOptions = _defaultOptions;
    this._position = 'below';
    this._disabled = false;
    this._viewInitialized = false;
    this._pointerExitEventsInitialized = false;
    /** The default delay in ms before showing the tooltip after show is called */
    this.showDelay = this._defaultOptions.showDelay;
    /** The default delay in ms before hiding the tooltip after hide is called */
    this.hideDelay = this._defaultOptions.hideDelay;
    /**
     * How touch gestures should be handled by the tooltip. On touch devices the tooltip directive
     * uses a long press gesture to show and hide, however it can conflict with the native browser
     * gestures. To work around the conflict, Angular Material disables native gestures on the
     * trigger, but that might not be desirable on particular elements (e.g. inputs and draggable
     * elements). The different values for this option configure the touch event handling as follows:
     * - `auto` - Enables touch gestures for all elements, but tries to avoid conflicts with native
     *   browser gestures on particular elements. In particular, it allows text selection on inputs
     *   and textareas, and preserves the native browser dragging on elements marked as `draggable`.
     * - `on` - Enables touch gestures for all elements and disables native
     *   browser gestures with no exceptions.
     * - `off` - Disables touch gestures. Note that this will prevent the tooltip from
     *   showing on touch devices.
     */
    this.touchGestures = 'auto';
    this._message = '';
    /** Manually-bound passive event listeners. */
    this._passiveListeners = [];
    /** Emits when the component is destroyed. */
    this._destroyed = new Subject();
    /**
     * Handles the keydown events on the host element.
     * Needs to be an arrow function so that we can use it in addEventListener.
     */
    this._handleKeydown = event => {
      if (this._isTooltipVisible() && event.keyCode === ESCAPE && !hasModifierKey(event)) {
        event.preventDefault();
        event.stopPropagation();
        this._ngZone.run(() => this.hide(0));
      }
    };
    this._scrollStrategy = scrollStrategy;
    if (_defaultOptions) {
      if (_defaultOptions.position) {
        this.position = _defaultOptions.position;
      }
      if (_defaultOptions.touchGestures) {
        this.touchGestures = _defaultOptions.touchGestures;
      }
    }
    _ngZone.runOutsideAngular(() => {
      _elementRef.nativeElement.addEventListener('keydown', this._handleKeydown);
    });
  }
  /** Allows the user to define the position of the tooltip relative to the parent element */
  get position() {
    return this._position;
  }
  set position(value) {
    if (value !== this._position) {
      this._position = value;
      if (this._overlayRef) {
        this._updatePosition();
        if (this._tooltipInstance) {
          this._tooltipInstance.show(0);
        }
        this._overlayRef.updatePosition();
      }
    }
  }
  /** Disables the display of the tooltip. */
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    // If tooltip is disabled, hide immediately.
    if (this._disabled) {
      this.hide(0);
    } else {
      this._setupPointerEnterEventsIfNeeded();
    }
  }
  /** The message to be displayed in the tooltip */
  get message() {
    return this._message;
  }
  set message(value) {
    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this._message);
    // TODO: If the message is a TemplateRef, it's hard to support a11y.
    // If the message is not a string (e.g. number), convert it to a string and trim it.
    this._message = value instanceof TemplateRef ? value : value != null ? `${value}`.trim() : '';
    if (!this._message && this._isTooltipVisible()) {
      this.hide(0);
    } else {
      this._setupPointerEnterEventsIfNeeded();
      this._updateTooltipMessage();
      this._ngZone.runOutsideAngular(() => {
        // The `AriaDescriber` has some functionality that avoids adding a description if it's the
        // same as the `aria-label` of an element, however we can't know whether the tooltip trigger
        // has a data-bound `aria-label` or when it'll be set for the first time. We can avoid the
        // issue by deferring the description by a tick so Angular has time to set the `aria-label`.
        Promise.resolve().then(() => {
          this._ariaDescriber.describe(this._elementRef.nativeElement, this.message);
        });
      });
    }
  }
  /** Context to be passed to the tooltip. */
  get tooltipContext() {
    return this._tooltipContext;
  }
  set tooltipContext(value) {
    this._tooltipContext = value;
    this._setTooltipContext(this._tooltipContext);
  }
  /** Classes to be passed to the tooltip. Supports the same syntax as `ngClass`. */
  get tooltipClass() {
    return this._tooltipClass;
  }
  set tooltipClass(value) {
    this._tooltipClass = value;
    if (this._tooltipInstance) {
      this._setTooltipClass(this._tooltipClass);
    }
  }
  ngAfterViewInit() {
    // This needs to happen after view init so the initial values for all inputs have been set.
    this._viewInitialized = true;
    this._setupPointerEnterEventsIfNeeded();
    this._focusMonitor.monitor(this._elementRef).pipe(takeUntil(this._destroyed)).subscribe(origin => {
      // Note that the focus monitor runs outside the Angular zone.
      if (!origin) {
        this._ngZone.run(() => this.hide(0));
      } else if (origin === 'keyboard') {
        this._ngZone.run(() => this.show());
      }
    });
  }
  /**
   * Dispose the tooltip when destroyed.
   */
  ngOnDestroy() {
    const nativeElement = this._elementRef.nativeElement;
    clearTimeout(this._touchstartTimeout);
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._tooltipInstance = null;
    }
    // Clean up the event listeners set in the constructor
    nativeElement.removeEventListener('keydown', this._handleKeydown);
    this._passiveListeners.forEach(([event, listener]) => {
      nativeElement.removeEventListener(event, listener, passiveListenerOptions);
    });
    this._passiveListeners.length = 0;
    this._destroyed.next();
    this._destroyed.complete();
    this._ariaDescriber.removeDescription(nativeElement, this.message);
    this._focusMonitor.stopMonitoring(nativeElement);
  }
  /** Shows the tooltip after the delay in ms, defaults to tooltip-delay-show or 0ms if no input */
  show(delay = this.showDelay) {
    if (this.disabled || !this.message || this._isTooltipVisible() && !this._tooltipInstance._showTimeoutId && !this._tooltipInstance._hideTimeoutId) {
      return;
    }
    const overlayRef = this._createOverlay();
    this._detach();
    this._portal = this._portal || new ComponentPortal(TooltipComponent, this._viewContainerRef);
    this._tooltipInstance = overlayRef.attach(this._portal).instance;
    this._tooltipInstance.afterHidden().pipe(takeUntil(this._destroyed)).subscribe(() => this._detach());
    this._setTooltipClass(this._tooltipClass);
    this._setTooltipContext(this._tooltipContext);
    this._updateTooltipMessage();
    this._tooltipInstance.show(delay);
  }
  /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
  hide(delay = this.hideDelay) {
    if (this._tooltipInstance) {
      this._tooltipInstance.hide(delay);
    }
  }
  /** Shows/hides the tooltip */
  toggle() {
    this._isTooltipVisible() ? this.hide() : this.show();
  }
  /** Returns true if the tooltip is currently visible to the user */
  _isTooltipVisible() {
    return !!this._tooltipInstance && this._tooltipInstance.isVisible();
  }
  /** Create the overlay config and position strategy */
  _createOverlay() {
    if (this._overlayRef) {
      return this._overlayRef;
    }
    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);
    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this._overlay.position().flexibleConnectedTo(this._elementRef).withTransformOriginOn('.mtx-tooltip').withFlexibleDimensions(false).withViewportMargin(8).withScrollableContainers(scrollableAncestors);
    strategy.positionChanges.pipe(takeUntil(this._destroyed)).subscribe(change => {
      if (this._tooltipInstance) {
        if (change.scrollableViewProperties.isOverlayClipped && this._tooltipInstance.isVisible()) {
          // After position changes occur and the overlay is clipped by
          // a parent scrollable then close the tooltip.
          this._ngZone.run(() => this.hide(0));
        }
      }
    });
    this._overlayRef = this._overlay.create({
      direction: this._dir,
      positionStrategy: strategy,
      panelClass: TOOLTIP_PANEL_CLASS,
      scrollStrategy: this._scrollStrategy()
    });
    this._updatePosition();
    this._overlayRef.detachments().pipe(takeUntil(this._destroyed)).subscribe(() => this._detach());
    return this._overlayRef;
  }
  /** Detaches the currently-attached tooltip. */
  _detach() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
    this._tooltipInstance = null;
  }
  /** Updates the position of the current tooltip. */
  _updatePosition() {
    const position = this._overlayRef.getConfig().positionStrategy;
    const origin = this._getOrigin();
    const overlay = this._getOverlayPosition();
    position.withPositions([{
      ...origin.main,
      ...overlay.main
    }, {
      ...origin.fallback,
      ...overlay.fallback
    }]);
  }
  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  _getOrigin() {
    const isLtr = !this._dir || this._dir.value === 'ltr';
    const position = this.position;
    let originPosition;
    if (position === 'above' || position === 'below') {
      originPosition = {
        originX: 'center',
        originY: position === 'above' ? 'top' : 'bottom'
      };
    } else if (position === 'before' || position === 'left' && isLtr || position === 'right' && !isLtr) {
      originPosition = {
        originX: 'start',
        originY: 'center'
      };
    } else if (position === 'after' || position === 'right' && isLtr || position === 'left' && !isLtr) {
      originPosition = {
        originX: 'end',
        originY: 'center'
      };
    } else {
      throw getMtxTooltipInvalidPositionError(position);
    }
    const {
      x,
      y
    } = this._invertPosition(originPosition.originX, originPosition.originY);
    return {
      main: originPosition,
      fallback: {
        originX: x,
        originY: y
      }
    };
  }
  /** Returns the overlay position and a fallback position based on the user's preference */
  _getOverlayPosition() {
    const isLtr = !this._dir || this._dir.value === 'ltr';
    const position = this.position;
    let overlayPosition;
    if (position === 'above') {
      overlayPosition = {
        overlayX: 'center',
        overlayY: 'bottom'
      };
    } else if (position === 'below') {
      overlayPosition = {
        overlayX: 'center',
        overlayY: 'top'
      };
    } else if (position === 'before' || position === 'left' && isLtr || position === 'right' && !isLtr) {
      overlayPosition = {
        overlayX: 'end',
        overlayY: 'center'
      };
    } else if (position === 'after' || position === 'right' && isLtr || position === 'left' && !isLtr) {
      overlayPosition = {
        overlayX: 'start',
        overlayY: 'center'
      };
    } else {
      throw getMtxTooltipInvalidPositionError(position);
    }
    const {
      x,
      y
    } = this._invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);
    return {
      main: overlayPosition,
      fallback: {
        overlayX: x,
        overlayY: y
      }
    };
  }
  /** Updates the tooltip message and repositions the overlay according to the new message length */
  _updateTooltipMessage() {
    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    if (this._tooltipInstance) {
      this._tooltipInstance.message = this.message;
      this._tooltipInstance._markForCheck();
      this._ngZone.onMicrotaskEmpty.asObservable().pipe(take(1), takeUntil(this._destroyed)).subscribe(() => {
        if (this._tooltipInstance) {
          this._overlayRef.updatePosition();
        }
      });
    }
  }
  /** Updates the tooltip context */
  _setTooltipContext(tooltipContext) {
    if (this._tooltipInstance) {
      this._tooltipInstance.tooltipContext = tooltipContext;
      this._tooltipInstance._markForCheck();
    }
  }
  /** Updates the tooltip class */
  _setTooltipClass(tooltipClass) {
    if (this._tooltipInstance) {
      this._tooltipInstance.tooltipClass = tooltipClass;
      this._tooltipInstance._markForCheck();
    }
  }
  /** Inverts an overlay position. */
  _invertPosition(x, y) {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }
    return {
      x,
      y
    };
  }
  /** Binds the pointer events to the tooltip trigger. */
  _setupPointerEnterEventsIfNeeded() {
    // Optimization: Defer hooking up events if there's no message or the tooltip is disabled.
    if (this._disabled || !this.message || !this._viewInitialized || this._passiveListeners.length) {
      return;
    }
    // The mouse events shouldn't be bound on mobile devices, because they can prevent the
    // first tap from firing its click event or can cause the tooltip to open for clicks.
    if (this._platformSupportsMouseEvents()) {
      this._passiveListeners.push(['mouseenter', () => {
        this._setupPointerExitEventsIfNeeded();
        this.show();
      }]);
    } else if (this.touchGestures !== 'off') {
      this._disableNativeGesturesIfNecessary();
      this._passiveListeners.push(['touchstart', () => {
        // Note that it's important that we don't `preventDefault` here,
        // because it can prevent click events from firing on the element.
        this._setupPointerExitEventsIfNeeded();
        clearTimeout(this._touchstartTimeout);
        this._touchstartTimeout = setTimeout(() => this.show(), LONGPRESS_DELAY);
      }]);
    }
    this._addListeners(this._passiveListeners);
  }
  _setupPointerExitEventsIfNeeded() {
    if (this._pointerExitEventsInitialized) {
      return;
    }
    this._pointerExitEventsInitialized = true;
    const exitListeners = [];
    if (this._platformSupportsMouseEvents()) {
      exitListeners.push(['mouseleave', () => this.hide()]);
    } else if (this.touchGestures !== 'off') {
      this._disableNativeGesturesIfNecessary();
      const touchendListener = () => {
        clearTimeout(this._touchstartTimeout);
        this.hide(this._defaultOptions.touchendHideDelay);
      };
      exitListeners.push(['touchend', touchendListener], ['touchcancel', touchendListener]);
    }
    this._addListeners(exitListeners);
    this._passiveListeners.push(...exitListeners);
  }
  _addListeners(listeners) {
    listeners.forEach(([event, listener]) => {
      this._elementRef.nativeElement.addEventListener(event, listener, passiveListenerOptions);
    });
  }
  _platformSupportsMouseEvents() {
    return !this._platform.IOS && !this._platform.ANDROID;
  }
  /** Disables the native browser gestures, based on how the tooltip has been configured. */
  _disableNativeGesturesIfNecessary() {
    const gestures = this.touchGestures;
    if (gestures !== 'off') {
      const element = this._elementRef.nativeElement;
      const style = element.style;
      // If gestures are set to `auto`, we don't disable text selection on inputs and
      // textareas, because it prevents the user from typing into them on iOS Safari.
      if (gestures === 'on' || element.nodeName !== 'INPUT' && element.nodeName !== 'TEXTAREA') {
        style.userSelect = style.msUserSelect = style.webkitUserSelect = style.MozUserSelect = 'none';
      }
      // If we have `auto` gestures and the element uses native HTML dragging,
      // we don't set `-webkit-user-drag` because it prevents the native behavior.
      if (gestures === 'on' || !element.draggable) {
        style.webkitUserDrag = 'none';
      }
      style.touchAction = 'none';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style.webkitTapHighlightColor = 'transparent';
    }
  }
}
/** @nocollapse */
MtxTooltip.ɵfac = function MtxTooltip_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxTooltip)(i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.ScrollDispatcher), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i3.Platform), i0.ɵɵdirectiveInject(i4.AriaDescriber), i0.ɵɵdirectiveInject(i4.FocusMonitor), i0.ɵɵdirectiveInject(MTX_TOOLTIP_SCROLL_STRATEGY), i0.ɵɵdirectiveInject(i5.Directionality, 8), i0.ɵɵdirectiveInject(MTX_TOOLTIP_DEFAULT_OPTIONS, 8));
};
/** @nocollapse */
MtxTooltip.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MtxTooltip,
  selectors: [["", "mtxTooltip", ""]],
  hostAttrs: [1, "mtx-tooltip-trigger"],
  inputs: {
    position: [0, "mtxTooltipPosition", "position"],
    disabled: [0, "mtxTooltipDisabled", "disabled"],
    showDelay: [0, "mtxTooltipShowDelay", "showDelay"],
    hideDelay: [0, "mtxTooltipHideDelay", "hideDelay"],
    touchGestures: [0, "mtxTooltipTouchGestures", "touchGestures"],
    message: [0, "mtxTooltip", "message"],
    tooltipContext: [0, "mtxTooltipContext", "tooltipContext"],
    tooltipClass: [0, "mtxTooltipClass", "tooltipClass"]
  },
  exportAs: ["mtxTooltip"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxTooltip, [{
    type: Directive,
    args: [{
      selector: '[mtxTooltip]',
      exportAs: 'mtxTooltip',
      host: {
        class: 'mtx-tooltip-trigger'
      }
    }]
  }], function () {
    return [{
      type: i1.Overlay
    }, {
      type: i0.ElementRef
    }, {
      type: i2.ScrollDispatcher
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }, {
      type: i3.Platform
    }, {
      type: i4.AriaDescriber
    }, {
      type: i4.FocusMonitor
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MTX_TOOLTIP_SCROLL_STRATEGY]
      }]
    }, {
      type: i5.Directionality,
      decorators: [{
        type: Optional
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MTX_TOOLTIP_DEFAULT_OPTIONS]
      }]
    }];
  }, {
    position: [{
      type: Input,
      args: ['mtxTooltipPosition']
    }],
    disabled: [{
      type: Input,
      args: ['mtxTooltipDisabled']
    }],
    showDelay: [{
      type: Input,
      args: ['mtxTooltipShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['mtxTooltipHideDelay']
    }],
    touchGestures: [{
      type: Input,
      args: ['mtxTooltipTouchGestures']
    }],
    message: [{
      type: Input,
      args: ['mtxTooltip']
    }],
    tooltipContext: [{
      type: Input,
      args: ['mtxTooltipContext']
    }],
    tooltipClass: [{
      type: Input,
      args: ['mtxTooltipClass']
    }]
  });
})();
/**
 * Internal component that wraps the tooltip's content.
 * @docs-private
 */
class TooltipComponent {
  constructor(_changeDetectorRef, _breakpointObserver) {
    this._changeDetectorRef = _changeDetectorRef;
    this._breakpointObserver = _breakpointObserver;
    /** Property watched by the animation framework to show or hide the tooltip */
    this._visibility = 'initial';
    /** Whether interactions on the page should close the tooltip */
    this._closeOnInteraction = false;
    /** Subject for notifying that the tooltip has been hidden from the view */
    this._onHide = new Subject();
    /** Stream that emits whether the user has a handset-sized display.  */
    this._isHandset = this._breakpointObserver.observe(Breakpoints.Handset);
  }
  /**
   * Shows the tooltip with an animation originating from the provided origin
   * @param delay Amount of milliseconds to the delay showing the tooltip.
   */
  show(delay) {
    // Cancel the delayed hide if it is scheduled
    if (this._hideTimeoutId) {
      clearTimeout(this._hideTimeoutId);
      this._hideTimeoutId = null;
    }
    // Body interactions should cancel the tooltip if there is a delay in showing.
    this._closeOnInteraction = true;
    this._showTimeoutId = setTimeout(() => {
      this._visibility = 'visible';
      this._showTimeoutId = null;
      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }
  /**
   * Begins the animation to hide the tooltip after the provided delay in ms.
   * @param delay Amount of milliseconds to delay showing the tooltip.
   */
  hide(delay) {
    // Cancel the delayed show if it is scheduled
    if (this._showTimeoutId) {
      clearTimeout(this._showTimeoutId);
      this._showTimeoutId = null;
    }
    this._hideTimeoutId = setTimeout(() => {
      this._visibility = 'hidden';
      this._hideTimeoutId = null;
      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }
  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden() {
    return this._onHide.asObservable();
  }
  /** Whether the tooltip is being displayed. */
  isVisible() {
    return this._visibility === 'visible';
  }
  ngOnDestroy() {
    this._onHide.complete();
  }
  _animationStart() {
    this._closeOnInteraction = false;
  }
  _animationDone(event) {
    const toState = event.toState;
    if (toState === 'hidden' && !this.isVisible()) {
      this._onHide.next();
    }
    if (toState === 'visible' || toState === 'hidden') {
      this._closeOnInteraction = true;
    }
  }
  /**
   * Interactions on the HTML body should close the tooltip immediately as defined in the
   * material design spec.
   * https://material.io/design/components/tooltips.html#behavior
   */
  _handleBodyInteraction() {
    if (this._closeOnInteraction) {
      this.hide(0);
    }
  }
  /**
   * Marks that the tooltip needs to be checked in the next change detection run.
   * Mainly used for rendering the initial text before positioning a tooltip, which
   * can be problematic in components with OnPush change detection.
   */
  _markForCheck() {
    this._changeDetectorRef.markForCheck();
  }
}
/** @nocollapse */
TooltipComponent.ɵfac = function TooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i6.BreakpointObserver));
};
/** @nocollapse */
TooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TooltipComponent,
  selectors: [["mtx-tooltip-component"]],
  hostAttrs: ["aria-hidden", "true"],
  hostVars: 2,
  hostBindings: function TooltipComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TooltipComponent_click_HostBindingHandler() {
        return ctx._handleBodyInteraction();
      }, false, i0.ɵɵresolveBody);
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("zoom", ctx._visibility === "visible" ? 1 : null);
    }
  },
  decls: 6,
  vars: 10,
  consts: [["msgStrTpl", ""], [1, "mtx-tooltip", 3, "ngClass"], [3, "ngIf", "ngIfElse"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function TooltipComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵlistener("@state.start", function TooltipComponent_Template_div_animation_state_start_0_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx._animationStart());
      })("@state.done", function TooltipComponent_Template_div_animation_state_done_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx._animationDone($event));
      });
      i0.ɵɵtemplate(2, TooltipComponent_ng_template_2_Template, 1, 4, "ng-template", 2);
      i0.ɵɵpipe(3, "isTemplateRef");
      i0.ɵɵtemplate(4, TooltipComponent_ng_template_4_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      let tmp_1_0;
      const msgStrTpl_r3 = i0.ɵɵreference(5);
      i0.ɵɵclassProp("mtx-tooltip-handset", (tmp_1_0 = i0.ɵɵpipeBind1(1, 6, ctx._isHandset)) == null ? null : tmp_1_0.matches);
      i0.ɵɵproperty("ngClass", ctx.tooltipClass)("@state", ctx._visibility);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 8, ctx.message))("ngIfElse", msgStrTpl_r3);
    }
  },
  dependencies: [i7.NgClass, i7.NgIf, i7.NgTemplateOutlet, i7.AsyncPipe, i8.MtxIsTemplateRefPipe],
  styles: [".mtx-tooltip-panel{pointer-events:none!important}.mtx-tooltip{color:#fff;border-radius:4px;margin:14px;max-width:250px;padding-left:8px;padding-right:8px;overflow:hidden;text-overflow:ellipsis}.cdk-high-contrast-active .mtx-tooltip{outline:solid 1px}.mtx-tooltip-handset{margin:24px;padding-left:16px;padding-right:16px}\n"],
  encapsulation: 2,
  data: {
    animation: [mtxTooltipAnimations.tooltipState]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipComponent, [{
    type: Component,
    args: [{
      selector: 'mtx-tooltip-component',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [mtxTooltipAnimations.tooltipState],
      host: {
        // Forces the element to have a layout in IE and Edge. This fixes issues where the element
        // won't be rendered if the animations are disabled or there is no web animations polyfill.
        '[style.zoom]': '_visibility === "visible" ? 1 : null',
        '(body:click)': 'this._handleBodyInteraction()',
        'aria-hidden': 'true'
      },
      template: "<div class=\"mtx-tooltip\"\n     [ngClass]=\"tooltipClass\"\n     [class.mtx-tooltip-handset]=\"(_isHandset | async)?.matches\"\n     [@state]=\"_visibility\"\n     (@state.start)=\"_animationStart()\"\n     (@state.done)=\"_animationDone($event)\">\n  <ng-template [ngIf]=\"message | isTemplateRef\" [ngIfElse]=\"msgStrTpl\">\n    <ng-template [ngTemplateOutlet]=\"$any(message)\"\n                 [ngTemplateOutletContext]=\"{ $implicit: tooltipContext }\"></ng-template>\n  </ng-template>\n  <ng-template #msgStrTpl>{{message}}</ng-template>\n</div>\n",
      styles: [".mtx-tooltip-panel{pointer-events:none!important}.mtx-tooltip{color:#fff;border-radius:4px;margin:14px;max-width:250px;padding-left:8px;padding-right:8px;overflow:hidden;text-overflow:ellipsis}.cdk-high-contrast-active .mtx-tooltip{outline:solid 1px}.mtx-tooltip-handset{margin:24px;padding-left:16px;padding-right:16px}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i6.BreakpointObserver
    }];
  }, null);
})();
class MtxTooltipModule {}
/** @nocollapse */
MtxTooltipModule.ɵfac = function MtxTooltipModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxTooltipModule)();
};
/** @nocollapse */
MtxTooltipModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxTooltipModule
});
/** @nocollapse */
MtxTooltipModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
  imports: [A11yModule, CommonModule, OverlayModule, MatCommonModule, MtxPipesModule, MatCommonModule, CdkScrollableModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [A11yModule, CommonModule, OverlayModule, MatCommonModule, MtxPipesModule],
      exports: [MtxTooltip, TooltipComponent, MatCommonModule, CdkScrollableModule],
      declarations: [MtxTooltip, TooltipComponent],
      providers: [MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MTX_TOOLTIP_DEFAULT_OPTIONS, MTX_TOOLTIP_DEFAULT_OPTIONS_FACTORY, MTX_TOOLTIP_SCROLL_STRATEGY, MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY, MTX_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, MtxTooltip, MtxTooltipModule, SCROLL_THROTTLE_MS, TOOLTIP_PANEL_CLASS, TooltipComponent, getMtxTooltipInvalidPositionError, mtxTooltipAnimations };
