import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/progress-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import * as i2 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
const _c0 = ["*"];
function MtxLoaderComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
  }
}
function MtxLoaderComponent_div_1_mat_spinner_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-spinner", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("color", ctx_r0.color)("strokeWidth", ctx_r0.strokeWidth)("diameter", ctx_r0.diameter)("mode", ctx_r0.mode)("value", ctx_r0.value);
  }
}
function MtxLoaderComponent_div_1_mat_progress_bar_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("color", ctx_r0.color)("mode", ctx_r0.mode)("value", ctx_r0.value)("bufferValue", ctx_r0.bufferValue);
  }
}
function MtxLoaderComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, MtxLoaderComponent_div_1_mat_spinner_1_Template, 1, 5, "mat-spinner", 4)(2, MtxLoaderComponent_div_1_mat_progress_bar_2_Template, 1, 4, "mat-progress-bar", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "spinner");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "progressbar");
  }
}
class MtxLoaderComponent {
  constructor(_changeDetectorRef) {
    this._changeDetectorRef = _changeDetectorRef;
    /** The loader's type. Can be `spinner` or `progressbar` */
    this.type = 'spinner';
    /** Theme color palette for the component. */
    this.color = 'primary';
    /** Mode of the progress circle or the progress bar. */
    this.mode = 'indeterminate';
    /** Stroke width of the spinner loader. */
    this.strokeWidth = 4;
    /** The diameter of the spinner loader (will set width and height of svg). */
    this.diameter = 48;
    /** Buffer value of the progressbar loader. */
    this.bufferValue = 0;
    /** Value of the progress circle or the progress bar. */
    this.value = 0;
    this._loading = true;
    this._hasBackdrop = true;
  }
  /** Whether the loader is loading. */
  get loading() {
    return this._loading;
  }
  set loading(value) {
    this._loading = coerceBooleanProperty(value);
  }
  /** Whether the loader has a backdrop. */
  get hasBackdrop() {
    return this._hasBackdrop;
  }
  set hasBackdrop(value) {
    this._hasBackdrop = coerceBooleanProperty(value);
  }
}
/** @nocollapse */
MtxLoaderComponent.ɵfac = function MtxLoaderComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxLoaderComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
MtxLoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MtxLoaderComponent,
  selectors: [["mtx-loader"]],
  hostAttrs: [1, "mtx-loader"],
  hostVars: 2,
  hostBindings: function MtxLoaderComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("mtx-loader-loading", ctx.loading);
    }
  },
  inputs: {
    type: "type",
    color: "color",
    mode: "mode",
    strokeWidth: "strokeWidth",
    diameter: "diameter",
    bufferValue: "bufferValue",
    value: "value",
    loading: "loading",
    hasBackdrop: "hasBackdrop"
  },
  exportAs: ["mtxLoader"],
  ngContentSelectors: _c0,
  decls: 3,
  vars: 2,
  consts: [["class", "mtx-loader-backdrop", 4, "ngIf"], ["class", "mtx-loader-main", 4, "ngIf"], [1, "mtx-loader-backdrop"], [1, "mtx-loader-main"], [3, "color", "strokeWidth", "diameter", "mode", "value", 4, "ngIf"], [3, "color", "mode", "value", "bufferValue", 4, "ngIf"], [3, "color", "strokeWidth", "diameter", "mode", "value"], [3, "color", "mode", "value", "bufferValue"]],
  template: function MtxLoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, MtxLoaderComponent_div_0_Template, 1, 0, "div", 0)(1, MtxLoaderComponent_div_1_Template, 3, 2, "div", 1);
      i0.ɵɵprojection(2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.loading && ctx.hasBackdrop);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.loading);
    }
  },
  dependencies: [i1.NgIf, i2.MatProgressSpinner, i3.MatProgressBar],
  styles: [".mtx-loader{position:relative;display:block;width:100%;height:100%}.mtx-loader-main{position:absolute;top:0;left:0;z-index:2;display:flex;justify-content:center;align-items:center;width:100%;height:100%}.mtx-loader-main .mat-spinner{position:relative}.mtx-loader-main .mat-progress-bar{position:absolute;top:0;left:0;width:100%}.mtx-loader-backdrop{display:block;position:absolute;top:0;left:0;z-index:1;width:100%;height:100%;content:\"\"}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'mtx-loader',
      exportAs: 'mtxLoader',
      host: {
        'class': 'mtx-loader',
        '[class.mtx-loader-loading]': 'loading'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"mtx-loader-backdrop\" *ngIf=\"loading && hasBackdrop\"></div>\r\n<div class=\"mtx-loader-main\" *ngIf=\"loading\">\r\n  <mat-spinner *ngIf=\"type==='spinner'\"\r\n               [color]=\"color\"\r\n               [strokeWidth]=\"strokeWidth\"\r\n               [diameter]=\"diameter\"\r\n               [mode]=\"$any(mode)\"\r\n               [value]=\"value\">\r\n  </mat-spinner>\r\n\r\n  <mat-progress-bar *ngIf=\"type==='progressbar'\"\r\n                    [color]=\"color\"\r\n                    [mode]=\"$any(mode)\"\r\n                    [value]=\"value\"\r\n                    [bufferValue]=\"bufferValue\">\r\n  </mat-progress-bar>\r\n</div>\r\n<ng-content></ng-content>\r\n",
      styles: [".mtx-loader{position:relative;display:block;width:100%;height:100%}.mtx-loader-main{position:absolute;top:0;left:0;z-index:2;display:flex;justify-content:center;align-items:center;width:100%;height:100%}.mtx-loader-main .mat-spinner{position:relative}.mtx-loader-main .mat-progress-bar{position:absolute;top:0;left:0;width:100%}.mtx-loader-backdrop{display:block;position:absolute;top:0;left:0;z-index:1;width:100%;height:100%;content:\"\"}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    type: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    strokeWidth: [{
      type: Input
    }],
    diameter: [{
      type: Input
    }],
    bufferValue: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    hasBackdrop: [{
      type: Input
    }]
  });
})();
class MtxLoaderModule {}
/** @nocollapse */
MtxLoaderModule.ɵfac = function MtxLoaderModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxLoaderModule)();
};
/** @nocollapse */
MtxLoaderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxLoaderModule
});
/** @nocollapse */
MtxLoaderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxLoaderModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
      exports: [MtxLoaderComponent],
      declarations: [MtxLoaderComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MtxLoaderComponent, MtxLoaderModule };
