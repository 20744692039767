import * as i0 from '@angular/core';
import { Injectable, Optional, Inject, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DatetimeAdapter, MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import * as i1 from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
const moment = 'default' in _moment ? _moment.default : _moment;
function range(length, valueFunction) {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
class MomentDatetimeAdapter extends DatetimeAdapter {
  constructor(matDateLocale, matMomentAdapterOptions, _delegate) {
    super(_delegate);
    this._useUtc = false;
    this.setLocale(matDateLocale || moment.locale());
    this._useUtc = matMomentAdapterOptions.useUtc;
  }
  setLocale(locale) {
    super.setLocale(locale);
    const momentLocaleData = moment.localeData(locale);
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, i => super.createDate(2017, 0, i + 1).format('D')),
      hours: range(24, i => this.createDatetime(2017, 0, 1, i, 0).format('H')),
      minutes: range(60, i => this.createDatetime(2017, 0, 1, 1, i).format('m')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin()
    };
  }
  getHour(date) {
    return super.clone(date).hour();
  }
  getMinute(date) {
    return super.clone(date).minute();
  }
  isInNextMonth(startDate, endDate) {
    const nextMonth = this.getDateInNextMonth(startDate);
    return super.sameMonthAndYear(nextMonth, endDate);
  }
  createDatetime(year, month, date, hour, minute) {
    // Check for invalid month and date (except upper bound on date which we have to check after
    // creating the Date).
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    if (hour < 0 || hour > 23) {
      throw Error(`Invalid hour "${hour}". Hour has to be between 0 and 23.`);
    }
    if (minute < 0 || minute > 59) {
      throw Error(`Invalid minute "${minute}". Minute has to be between 0 and 59.`);
    }
    let result;
    if (this._useUtc) {
      result = moment.utc({
        year,
        month,
        date,
        hour,
        minute
      });
    } else {
      result = moment({
        year,
        month,
        date,
        hour,
        minute
      });
    }
    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result.locale(this.locale);
  }
  getFirstDateOfMonth(date) {
    return super.clone(date).startOf('month');
  }
  getHourNames() {
    return this._localeData.hours;
  }
  getMinuteNames() {
    return this._localeData.minutes;
  }
  addCalendarHours(date, hours) {
    return super.clone(date).add({
      hours
    });
  }
  addCalendarMinutes(date, minutes) {
    return super.clone(date).add({
      minutes
    });
  }
  deserialize(value) {
    return this._delegate.deserialize(value);
  }
  getDateInNextMonth(date) {
    return super.clone(date).date(1).add({
      month: 1
    });
  }
}
/** @nocollapse */
MomentDatetimeAdapter.ɵfac = function MomentDatetimeAdapter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MomentDatetimeAdapter)(i0.ɵɵinject(MAT_DATE_LOCALE, 8), i0.ɵɵinject(MAT_MOMENT_DATE_ADAPTER_OPTIONS, 8), i0.ɵɵinject(i1.DateAdapter));
};
/** @nocollapse */
MomentDatetimeAdapter.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MomentDatetimeAdapter,
  factory: MomentDatetimeAdapter.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentDatetimeAdapter, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_DATE_LOCALE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      }]
    }, {
      type: i1.DateAdapter
    }];
  }, null);
})();
const MTX_MOMENT_DATETIME_FORMATS = {
  parse: {
    dateInput: 'L',
    monthInput: 'MMMM',
    yearInput: 'YYYY',
    datetimeInput: 'L LT',
    timeInput: 'LT'
  },
  display: {
    dateInput: 'L',
    monthInput: 'MMMM',
    yearInput: 'YYYY',
    datetimeInput: 'L LT',
    timeInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    popupHeaderDateLabel: 'ddd, DD MMM'
  }
};
class MomentDatetimeModule {}
/** @nocollapse */
MomentDatetimeModule.ɵfac = function MomentDatetimeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MomentDatetimeModule)();
};
/** @nocollapse */
MomentDatetimeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MomentDatetimeModule
});
/** @nocollapse */
MomentDatetimeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: DatetimeAdapter,
    useClass: MomentDatetimeAdapter
  }],
  imports: [MomentDateModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentDatetimeModule, [{
    type: NgModule,
    args: [{
      imports: [MomentDateModule],
      providers: [{
        provide: DatetimeAdapter,
        useClass: MomentDatetimeAdapter
      }]
    }]
  }], null, null);
})();
class MtxMomentDatetimeModule {}
/** @nocollapse */
MtxMomentDatetimeModule.ɵfac = function MtxMomentDatetimeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxMomentDatetimeModule)();
};
/** @nocollapse */
MtxMomentDatetimeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxMomentDatetimeModule
});
/** @nocollapse */
MtxMomentDatetimeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: MTX_DATETIME_FORMATS,
    useValue: MTX_MOMENT_DATETIME_FORMATS
  }],
  imports: [MatMomentDateModule, MomentDatetimeModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxMomentDatetimeModule, [{
    type: NgModule,
    args: [{
      imports: [MatMomentDateModule, MomentDatetimeModule],
      providers: [{
        provide: MTX_DATETIME_FORMATS,
        useValue: MTX_MOMENT_DATETIME_FORMATS
      }]
    }]
  }], null, null);
})();
/**
 * @deprecated Use `MtxMomentDatetimeModule` instead.
 */
const MatMomentDatetimeModule = MtxMomentDatetimeModule;

/**
 * Generated bundle index. Do not edit.
 */

export { MTX_MOMENT_DATETIME_FORMATS, MatMomentDatetimeModule, MomentDatetimeAdapter, MomentDatetimeModule, MtxMomentDatetimeModule };
