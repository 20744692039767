import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChildren, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i4 from '@angular/material/checkbox';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import * as i5 from '@ng-matero/extensions/core';
import { MtxPipesModule } from '@ng-matero/extensions/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i1 from '@angular/cdk/a11y';
const _c0 = () => ({
  standalone: true
});
function MtxCheckboxGroupComponent_mat_checkbox_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 2);
    i0.ɵɵtwoWayListener("indeterminateChange", function MtxCheckboxGroupComponent_mat_checkbox_0_Template_mat_checkbox_indeterminateChange_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.selectAllIndeterminate, $event) || (ctx_r1.selectAllIndeterminate = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵlistener("change", function MtxCheckboxGroupComponent_mat_checkbox_0_Template_mat_checkbox_change_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._updateMasterCheckboxState($event, -1));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("checked", ctx_r1.selectAll);
    i0.ɵɵtwoWayProperty("indeterminate", ctx_r1.selectAllIndeterminate);
    i0.ɵɵproperty("disabled", ctx_r1.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.selectAllLabel, "\n");
  }
}
function MtxCheckboxGroupComponent_mat_checkbox_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 3);
    i0.ɵɵtwoWayListener("ngModelChange", function MtxCheckboxGroupComponent_mat_checkbox_1_Template_mat_checkbox_ngModelChange_0_listener($event) {
      const option_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      i0.ɵɵtwoWayBindingSet(option_r4.checked, $event) || (option_r4.checked = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵlistener("change", function MtxCheckboxGroupComponent_mat_checkbox_1_Template_mat_checkbox_change_0_listener($event) {
      const i_r5 = i0.ɵɵrestoreView(_r3).index;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._updateNormalCheckboxState($event, i_r5));
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "toObservable");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtwoWayProperty("ngModel", option_r4.checked);
    i0.ɵɵproperty("ngModelOptions", i0.ɵɵpureFunction0(15, _c0))("aria-describedby", option_r4.ariaDescribedby)("aria-label", option_r4.ariaLabel)("aria-labelledby", option_r4.ariaLabelledby)("color", option_r4.color)("disabled", option_r4.disabled || ctx_r1.disabled)("disableRipple", option_r4.disableRipple)("labelPosition", option_r4.labelPosition)("required", option_r4.required);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 13, i0.ɵɵpipeBind1(2, 11, option_r4[ctx_r1.bindLabel])), "\n");
  }
}
class MtxCheckboxBase {
  constructor(label, value) {
    this.label = label;
    this.value = value;
  }
}
class MtxCheckboxGroupComponent {
  constructor(_changeDetectorRef, _focusMonitor, _elementRef) {
    this._changeDetectorRef = _changeDetectorRef;
    this._focusMonitor = _focusMonitor;
    this._elementRef = _elementRef;
    this._items = [];
    this._originalItems = [];
    this.bindLabel = 'label';
    this.bindValue = 'value';
    this._showSelectAll = false;
    this.selectAllLabel = 'Select All';
    this._disabled = false;
    this.change = new EventEmitter();
    this.selectAll = false;
    this.selectAllIndeterminate = false;
    this.selectedItems = [];
    this._onChange = () => null;
    this._onTouched = () => null;
  }
  get items() {
    return this._items;
  }
  set items(value) {
    // store the original data with deep clone
    this._originalItems = JSON.parse(JSON.stringify(value));
    this._items = value.map(option => {
      return option instanceof Object ? {
        ...option
      } : new MtxCheckboxBase(option, option);
    });
  }
  get showSelectAll() {
    return this._showSelectAll;
  }
  set showSelectAll(value) {
    this._showSelectAll = coerceBooleanProperty(value);
  }
  get compareWith() {
    return this._compareWith;
  }
  set compareWith(fn) {
    if (fn != null && typeof fn !== 'function') {
      throw Error('`compareWith` must be a function.');
    }
    this._compareWith = fn;
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true).subscribe(focusOrigin => {
      if (!focusOrigin) {
        // When a focused element becomes disabled, the browser *immediately* fires a blur event.
        // Angular does not expect events to be raised during change detection, so any state change
        // (such as a form control's 'ng-touched') will cause a changed-after-checked error.
        // See https://github.com/angular/angular/issues/17793. To work around this, we defer
        // telling the form control it has been touched until the next tick.
        Promise.resolve().then(() => {
          this._onTouched();
          this._changeDetectorRef.markForCheck();
        });
      }
    });
  }
  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  /**
   * Finds and selects and option based on its value.
   * @returns Option that has the corresponding value.
   */
  _selectValue(value) {
    const correspondingOption = this.items.find(option => {
      try {
        const compareValue = option[this.bindValue] === value;
        return this._compareWith ? this._compareWith(option, value) : compareValue;
      } catch (error) {
        console.warn(error);
        return false;
      }
    });
    if (correspondingOption) {
      correspondingOption.checked = true;
    }
    return correspondingOption;
  }
  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value New value to be written to the model.
   */
  writeValue(value) {
    this.items.forEach(item => item.checked = false);
    if (value) {
      if (!Array.isArray(value)) {
        throw Error('Value must be an array.');
      }
      value.forEach(currentValue => this._selectValue(currentValue));
      this.selectedItems = value;
    }
    this._checkMasterCheckboxState();
    this._changeDetectorRef.markForCheck();
  }
  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn) {
    this._onChange = fn;
  }
  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  _checkMasterCheckboxState() {
    if (this.items.filter(option => option.checked || !option.disabled).every(option => !option.checked)) {
      this.selectAll = false;
      this.selectAllIndeterminate = false;
    } else if (this.items.filter(option => option.checked || !option.disabled).every(option => option.checked)) {
      this.selectAll = true;
      this.selectAllIndeterminate = false;
    } else {
      this.selectAllIndeterminate = true;
    }
  }
  _getSelectedItems(index) {
    this.selectedItems = this.items.filter(option => option.checked);
    if (this._compareWith) {
      this.selectedItems = this._originalItems.filter(option => this.selectedItems.find(selectedOption => this._compareWith(option, selectedOption)));
    } else {
      this.selectedItems = this.selectedItems.map(option => option[this.bindValue]);
    }
    this._onChange(this.selectedItems);
    this.change.emit({
      model: this.selectedItems,
      index
    });
  }
  /** Handle normal checkbox toggle */
  _updateNormalCheckboxState(e, index) {
    this._checkMasterCheckboxState();
    this._getSelectedItems(index);
  }
  /** Handle master checkbox toggle */
  _updateMasterCheckboxState(e, index) {
    this.selectAll = !this.selectAll;
    this.selectAllIndeterminate = false;
    if (this.selectAll) {
      this.items.filter(option => option.checked || !option.disabled).forEach(option => option.checked = true);
    } else {
      this.items.filter(option => option.checked || !option.disabled).forEach(option => option.checked = !!option.disabled);
    }
    this._getSelectedItems(index);
  }
}
/** @nocollapse */
MtxCheckboxGroupComponent.ɵfac = function MtxCheckboxGroupComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxCheckboxGroupComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.FocusMonitor), i0.ɵɵdirectiveInject(i0.ElementRef));
};
/** @nocollapse */
MtxCheckboxGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MtxCheckboxGroupComponent,
  selectors: [["mtx-checkbox-group"]],
  contentQueries: function MtxCheckboxGroupComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, MatCheckbox, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._checkboxes = _t);
    }
  },
  hostAttrs: [1, "mtx-checkbox-group"],
  inputs: {
    items: "items",
    bindLabel: "bindLabel",
    bindValue: "bindValue",
    showSelectAll: "showSelectAll",
    selectAllLabel: "selectAllLabel",
    compareWith: "compareWith",
    disabled: "disabled"
  },
  outputs: {
    change: "change"
  },
  exportAs: ["mtxCheckboxGroup"],
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MtxCheckboxGroupComponent),
    multi: true
  }])],
  decls: 2,
  vars: 2,
  consts: [["class", "mtx-checkbox-master", 3, "checked", "indeterminate", "disabled", "indeterminateChange", "change", 4, "ngIf"], ["class", "mtx-checkbox-normal", 3, "ngModel", "ngModelOptions", "aria-describedby", "aria-label", "aria-labelledby", "color", "disabled", "disableRipple", "labelPosition", "required", "ngModelChange", "change", 4, "ngFor", "ngForOf"], [1, "mtx-checkbox-master", 3, "indeterminateChange", "change", "checked", "indeterminate", "disabled"], [1, "mtx-checkbox-normal", 3, "ngModelChange", "change", "ngModel", "ngModelOptions", "aria-describedby", "aria-label", "aria-labelledby", "color", "disabled", "disableRipple", "labelPosition", "required"]],
  template: function MtxCheckboxGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, MtxCheckboxGroupComponent_mat_checkbox_0_Template, 2, 4, "mat-checkbox", 0)(1, MtxCheckboxGroupComponent_mat_checkbox_1_Template, 4, 16, "mat-checkbox", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.showSelectAll);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i2.NgForOf, i2.NgIf, i3.NgControlStatus, i3.RequiredValidator, i3.NgModel, i4.MatCheckbox, i4.MatCheckboxRequiredValidator, i2.AsyncPipe, i5.MtxToObservablePipe],
  styles: [".mtx-checkbox-group{display:block}.mtx-checkbox-group .mat-checkbox{margin-right:16px}[dir=rtl] .mtx-checkbox-group .mat-checkbox{margin-right:auto;margin-left:16px}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxCheckboxGroupComponent, [{
    type: Component,
    args: [{
      selector: 'mtx-checkbox-group',
      exportAs: 'mtxCheckboxGroup',
      host: {
        class: 'mtx-checkbox-group'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MtxCheckboxGroupComponent),
        multi: true
      }],
      template: "<mat-checkbox class=\"mtx-checkbox-master\"\n              *ngIf=\"showSelectAll\"\n              [checked]=\"selectAll\"\n              [(indeterminate)]=\"selectAllIndeterminate\"\n              [disabled]=\"disabled\"\n              (change)=\"_updateMasterCheckboxState($event, -1)\">\n  {{selectAllLabel}}\n</mat-checkbox>\n\n<mat-checkbox class=\"mtx-checkbox-normal\"\n              *ngFor=\"let option of items; let i = index;\"\n              [(ngModel)]=\"option.checked\"\n              [ngModelOptions]=\"{standalone: true}\"\n              [aria-describedby]=\"option.ariaDescribedby\"\n              [aria-label]=\"option.ariaLabel\"\n              [aria-labelledby]=\"option.ariaLabelledby\"\n              [color]=\"option.color\"\n              [disabled]=\"option.disabled || disabled\"\n              [disableRipple]=\"option.disableRipple\"\n              [labelPosition]=\"option.labelPosition\"\n              [required]=\"option.required\"\n              (change)=\"_updateNormalCheckboxState($event, i)\">\n  {{option[bindLabel] | toObservable | async}}\n</mat-checkbox>\n",
      styles: [".mtx-checkbox-group{display:block}.mtx-checkbox-group .mat-checkbox{margin-right:16px}[dir=rtl] .mtx-checkbox-group .mat-checkbox{margin-right:auto;margin-left:16px}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i1.FocusMonitor
    }, {
      type: i0.ElementRef
    }];
  }, {
    _checkboxes: [{
      type: ContentChildren,
      args: [forwardRef(() => MatCheckbox), {
        descendants: true
      }]
    }],
    items: [{
      type: Input
    }],
    bindLabel: [{
      type: Input
    }],
    bindValue: [{
      type: Input
    }],
    showSelectAll: [{
      type: Input
    }],
    selectAllLabel: [{
      type: Input
    }],
    compareWith: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class MtxCheckboxGroupModule {}
/** @nocollapse */
MtxCheckboxGroupModule.ɵfac = function MtxCheckboxGroupModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxCheckboxGroupModule)();
};
/** @nocollapse */
MtxCheckboxGroupModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxCheckboxGroupModule
});
/** @nocollapse */
MtxCheckboxGroupModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, MatCheckboxModule, MtxPipesModule, MtxPipesModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxCheckboxGroupModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, MatCheckboxModule, MtxPipesModule],
      exports: [MtxCheckboxGroupComponent, MtxPipesModule],
      declarations: [MtxCheckboxGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MtxCheckboxBase, MtxCheckboxGroupComponent, MtxCheckboxGroupModule };
