/* eslint-disable @typescript-eslint/member-ordering */
import moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { WndGridColumn } from '../interfaces/wnd-grid-column';
import { Store } from '@ngrx/store';
import { GridFiltersSelectors } from '@store/grid-filters-store';
import { IGridFiltersCollection } from '@store/grid-filters-store/state';


//region Interfaces
export enum WndGridColumnSearchType {
    CONTAINS = 'CONTAINS',
    EQUALS = 'EQUALS',
    MORE = 'MORE',
    LESS = 'LESS',
    MORE_EQUALS = 'MORE_EQUALS',
    LESS_EQUALS = 'LESS_EQUALS',
    INRANGE = 'INRANGE',
    INLIST = 'INLIST',
    EMPTY = 'EMPTY'
}


export interface WndGridColumnCalculation {
    type: WndGridColumnSearchType;
    displayName: string;
}


export interface WndGridColumnQueryEvent {
    type: WndGridColumnSearchType;
    arguments: string[];
}

//endregion


const AVAILABLE_CALCULATION = [
    {
        type: 'text',
        supported: [
            WndGridColumnSearchType.CONTAINS,
            WndGridColumnSearchType.EQUALS,
        ],
    },
    {
        type: 'number',
        supported: [
            WndGridColumnSearchType.EQUALS,
            WndGridColumnSearchType.INRANGE,
            WndGridColumnSearchType.LESS,
            WndGridColumnSearchType.LESS_EQUALS,
            WndGridColumnSearchType.MORE,
            WndGridColumnSearchType.MORE_EQUALS,
        ],
    },
    {
        type: 'date',
        supported: [
            WndGridColumnSearchType.EQUALS,
            WndGridColumnSearchType.INRANGE,
            WndGridColumnSearchType.LESS,
            WndGridColumnSearchType.LESS_EQUALS,
            WndGridColumnSearchType.MORE,
            WndGridColumnSearchType.MORE_EQUALS,
        ],
    },
    {
        type: 'boolean',
        supported: [
            WndGridColumnSearchType.EQUALS,
        ],
    },
    {
        type: 'icon',
        supported: [
            WndGridColumnSearchType.CONTAINS,
            WndGridColumnSearchType.EQUALS,
        ],
    },
    {
        type: 'button',
        supported: [],
    },
];

//endregion


export class WndGridColumnSearch {
    neverOpenedBefore = true;
    filterSource: BehaviorSubject<WndGridColumnCalculation>;
    firstArgSource = new BehaviorSubject<string | Moment | number | string[]>( '' );
    secondArgSource = new BehaviorSubject<string | Moment | number>( '' );
    errorMsg = new BehaviorSubject<string>( undefined );


    private startQuery = false;
    private lastEmitted: WndGridColumnCalculation;

    private readonly availableCalculation: { type: string; supported: WndGridColumnSearchType[] }[] = AVAILABLE_CALCULATION;

    constructor( private store: Store, private gridName: string, public column: WndGridColumn ) {
        this.filterSource = new BehaviorSubject<WndGridColumnCalculation>( this.supportedCalculation[ 0 ] );
        this.lastEmitted = this.supportedCalculation[ 0 ];

        // Take the initial filter from the store and set relative subjects if it is present
        this.store.select( GridFiltersSelectors.selectColumnFilter( gridName, column.fieldName ) ).pipe(
            take( 1 ),
            // tap( f => console.log( 'Filter from the store', f ) )
        ).subscribe( f => {
            this.initializeFilter( f );
        } );
    }

    resetFilters() {
        console.log( 'CF reset filter' );
        this.filterSource.next( this.supportedCalculation[ 0 ] );
        this.firstArgSource.next( '' );
        this.secondArgSource.next( '' );
    }


    get inRange() {
        return this.filterSource.value.type === WndGridColumnSearchType.INRANGE;
    }


    get filters() {
        return combineLatest( [ this.filterSource, this.firstArgSource, this.secondArgSource ] ).pipe(
            // tap( ( [ f, a1, a2 ] ) => console.debug( 'Filter changed ', f, a1, a2 ) ),
            debounceTime( 320 ),
            distinctUntilChanged(),
            filter( () => {
                if( !this.startQuery ) {
                    this.startQuery = !!this.firstArgSource.value || !!this.secondArgSource.value;
                }
                return ( this.isValid() && this.startQuery ) || this.filterSource.value.type !== this.lastEmitted.type;
            } ),
            // tap( f => console.log( 'Filter changed', this.column, f ) ),
            map( () => {
                let args: string[] = [];
                if( this.filterSource.value.type !== this.lastEmitted.type ) {
                    this.lastEmitted = this.filterSource.value;
                    this.firstArgSource.next( undefined );
                    this.secondArgSource.next( undefined );

                } else {
                    if( this.firstArgSource.value ) {
                        if( this.column?.type === 'date' ) {
                            args.push( moment( this.firstArgSource.value ).toISOString() );
                        } else if( this.filterSource.value.type === WndGridColumnSearchType.INLIST ) {
                            args = this.firstArgSource.value as string[];
                        } else {
                            args.push( this.firstArgSource.value.toString() );
                        }

                    }
                    if( this.secondArgSource.value ) {
                        if( this.column?.type === 'date' ) {
                            args.push( moment( this.secondArgSource.value as Moment ).toISOString() );
                        } else {
                            args.push( this.secondArgSource.value.toString() );
                        }
                    }
                }
                const event: WndGridColumnQueryEvent = {
                    type: this.filterSource.value.type,
                    arguments: args,
                };
                return event;
            } ),
            distinctUntilChanged( ( x, y ) => x.arguments.length === 0 && y.arguments.length === 0 && x.type === y.type ),
        );
    }


    private isValid() {
        let first = this.firstArgSource.value;
        let second = this.secondArgSource.value;
        // console.debug( 'IsValid', first, second );
        if( this.inRange ) {
            if( !first && !second ) {
                return true;
            }
            if( !first || !second ) {
                this.errorMsg.next( 'Intervallo errato' );
                return false;
            }

            if( this.column?.type === 'number' ) {
                if( first >= second ) {
                    this.errorMsg.next( 'Intervallo errato' );
                    return false;
                }
            } else {
                first = typeof first === 'string' ? moment( first, moment.ISO_8601 ) : first as Moment;
                second = typeof second === 'string' ? moment( second, moment.ISO_8601 ) : second as Moment;
                if( moment.duration( second.diff( first ) ).asHours() <= 0 ) {
                    this.errorMsg.next( 'Intervallo errato' );
                    return false;
                }
            }
        }
        this.errorMsg.next( undefined );
        return true;
    }

    get supportedCalculation(): WndGridColumnCalculation[] {
        const type = this.availableCalculation.find( c => c.type === this.column?.type );
        if( type ) {
            return type.supported.map( s => {
                const temp: WndGridColumnCalculation = {
                    type: s,
                    displayName: this.filterDisplayName( s ),
                };
                return temp;
            } );
        } else {
            console.warn( `Column type ${ this.column?.type } in column ${ this.column?.fieldName } is not supported` );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    private filterDisplayName( filter: WndGridColumnSearchType ) {
        switch( filter ) {
        case WndGridColumnSearchType.CONTAINS: {
            return 'Contiene';
        }
        case WndGridColumnSearchType.EQUALS: {
            return 'Uguale';
        }
        case WndGridColumnSearchType.INLIST: {
            return 'Nella lista';
        }
        case WndGridColumnSearchType.INRANGE: {
            return 'Nell\'intervallo';
        }
        case WndGridColumnSearchType.LESS: {
            return 'Minore';
        }
        case WndGridColumnSearchType.LESS_EQUALS: {
            return 'Minore o uguale';
        }
        case WndGridColumnSearchType.MORE: {
            return 'Maggiore';
        }
        case WndGridColumnSearchType.MORE_EQUALS: {
            return 'Maggiore o uguale';
        }
        default: {
            throw Error( 'Unsupported search type' );
        }
        }

    }

    /**
     * This function initialise the filter with data, eventually, coming from the store.
     * It is used to have persistent filters so that the user find the same filter when he came back to the page.
     * Of course, it works only if the user doesn't refresh the page or closes the browser.
     *
     * @param f     The filter returned by the store.
     * @private
     */
    private initializeFilter( f: IGridFiltersCollection ) {
        if( f ) {
            const initFilter: WndGridColumnCalculation = { type: f.filter.type, displayName: this.filterDisplayName( f.filter.type ) };
            this.lastEmitted = initFilter;
            this.filterSource.next( initFilter );
            if( f.filter.type !== WndGridColumnSearchType.INLIST ) {
                if( f.filter.arguments?.length > 0 && f.filter.arguments[ 0 ] ) {
                    this.firstArgSource.next( f.filter.arguments[ 0 ] );
                }
                if( f.filter.arguments?.length > 1 && f.filter.arguments[ 1 ] ) {
                    this.secondArgSource.next( f.filter.arguments[ 1 ] );
                }
            } else {
                this.firstArgSource.next( f.filter.arguments );
            }
        }
    }

}



