import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
const _c0 = ["*"];
function MtxAlertComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function MtxAlertComponent_ng_template_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._onClosed());
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵtext(2, "\xD7");
    i0.ɵɵelementEnd()();
  }
}
class MtxAlertComponent {
  constructor(_changeDetectorRef) {
    this._changeDetectorRef = _changeDetectorRef;
    /** The alert's type. Can be `default`, `info`, `success`, `warning` or `danger`. */
    this.type = 'default';
    this._dismissible = false;
    /** The alert's elevation (0~24). */
    this.elevation = 0;
    /** Event emitted when the alert closed. */
    this.closed = new EventEmitter();
  }
  get _hostClassList() {
    return `mtx-alert-${this.type} mat-elevation-z${this.elevation}`;
  }
  /** Whether to display an inline close button. */
  get dismissible() {
    return this._dismissible;
  }
  set dismissible(value) {
    this._dismissible = coerceBooleanProperty(value);
  }
  _onClosed() {
    this._changeDetectorRef.markForCheck();
    this.closed.emit(this);
  }
}
/** @nocollapse */
MtxAlertComponent.ɵfac = function MtxAlertComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxAlertComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
MtxAlertComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MtxAlertComponent,
  selectors: [["mtx-alert"]],
  hostAttrs: ["role", "alert"],
  hostVars: 6,
  hostBindings: function MtxAlertComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassMap(ctx._hostClassList);
      i0.ɵɵclassProp("mtx-alert", true)("mtx-alert-dismissible", ctx.dismissible);
    }
  },
  inputs: {
    type: "type",
    dismissible: "dismissible",
    elevation: "elevation"
  },
  outputs: {
    closed: "closed"
  },
  exportAs: ["mtxAlert"],
  ngContentSelectors: _c0,
  decls: 2,
  vars: 1,
  consts: [[3, "ngIf"], ["type", "button", "aria-label", "Close", 1, "mtx-alert-close", 3, "click"], ["aria-hidden", "true"]],
  template: function MtxAlertComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵtemplate(1, MtxAlertComponent_ng_template_1_Template, 3, 0, "ng-template", 0);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.dismissible);
    }
  },
  dependencies: [i1.NgIf],
  styles: [".mtx-alert{position:relative;display:block;padding:.75rem 1.25rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.25rem}.mtx-alert-close{position:absolute;top:0;bottom:0;right:0;padding:0 1.25rem;font-size:1.5rem;line-height:1;color:inherit;opacity:.5;background-color:transparent;border:0;cursor:pointer}[dir=rtl] .mtx-alert-close{right:auto;left:0}.mtx-alert-close:hover{opacity:.75}.mtx-alert-dismissible{padding-right:4rem}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxAlertComponent, [{
    type: Component,
    args: [{
      selector: 'mtx-alert',
      exportAs: 'mtxAlert',
      host: {
        '[class.mtx-alert]': 'true',
        '[class.mtx-alert-dismissible]': 'dismissible',
        'role': 'alert'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\n<ng-template [ngIf]=\"dismissible\">\n  <button type=\"button\" class=\"mtx-alert-close\" aria-label=\"Close\" (click)=\"_onClosed()\">\n    <span aria-hidden=\"true\">&times;</span>\n  </button>\n</ng-template>\n",
      styles: [".mtx-alert{position:relative;display:block;padding:.75rem 1.25rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.25rem}.mtx-alert-close{position:absolute;top:0;bottom:0;right:0;padding:0 1.25rem;font-size:1.5rem;line-height:1;color:inherit;opacity:.5;background-color:transparent;border:0;cursor:pointer}[dir=rtl] .mtx-alert-close{right:auto;left:0}.mtx-alert-close:hover{opacity:.75}.mtx-alert-dismissible{padding-right:4rem}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    _hostClassList: [{
      type: HostBinding,
      args: ['class']
    }],
    type: [{
      type: Input
    }],
    dismissible: [{
      type: Input
    }],
    elevation: [{
      type: Input
    }],
    closed: [{
      type: Output
    }]
  });
})();
class MtxAlertModule {}
/** @nocollapse */
MtxAlertModule.ɵfac = function MtxAlertModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxAlertModule)();
};
/** @nocollapse */
MtxAlertModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxAlertModule
});
/** @nocollapse */
MtxAlertModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxAlertModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [MtxAlertComponent],
      declarations: [MtxAlertComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MtxAlertComponent, MtxAlertModule };
