import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
class MatButtonLoadingDirective {
  constructor(matButton, viewContainerRef, renderer) {
    this.matButton = matButton;
    this.viewContainerRef = viewContainerRef;
    this.renderer = renderer;
    this._loading = false;
    this._disabled = false;
  }
  get loading() {
    return this._loading;
  }
  set loading(value) {
    this._loading = coerceBooleanProperty(value);
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
  }
  ngOnChanges(changes) {
    if (!changes.loading) {
      return;
    }
    if (changes.loading.currentValue) {
      this.matButton._elementRef.nativeElement.classList.add('mat-button-loading');
      this.matButton.disabled = true;
      this.createSpinner();
    } else if (!changes.loading.firstChange) {
      this.matButton._elementRef.nativeElement.classList.remove('mat-button-loading');
      this.matButton.disabled = this.disabled;
      this.destroySpinner();
    }
  }
  createSpinner() {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
      this.spinner.instance.color = this.color;
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this.renderer.appendChild(this.matButton._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
    }
  }
  destroySpinner() {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
/** @nocollapse */
MatButtonLoadingDirective.ɵfac = function MatButtonLoadingDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MatButtonLoadingDirective)(i0.ɵɵdirectiveInject(i1.MatButton), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
/** @nocollapse */
MatButtonLoadingDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MatButtonLoadingDirective,
  selectors: [["button", "mat-button", "", "loading", ""], ["button", "mat-raised-button", "", "loading", ""], ["button", "mat-stroked-button", "", "loading", ""], ["button", "mat-flat-button", "", "loading", ""], ["button", "mat-icon-button", "", "loading", ""], ["button", "mat-fab", "", "loading", ""], ["button", "mat-mini-fab", "", "loading", ""]],
  inputs: {
    loading: "loading",
    disabled: "disabled",
    color: "color"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatButtonLoadingDirective, [{
    type: Directive,
    args: [{
      selector: `button[mat-button][loading],
             button[mat-raised-button][loading],
             button[mat-stroked-button][loading],
             button[mat-flat-button][loading],
             button[mat-icon-button][loading],
             button[mat-fab][loading],
             button[mat-mini-fab][loading]`
    }]
  }], function () {
    return [{
      type: i1.MatButton
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    loading: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    color: [{
      type: Input
    }]
  });
})();
class MtxButtonModule {}
/** @nocollapse */
MtxButtonModule.ɵfac = function MtxButtonModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MtxButtonModule)();
};
/** @nocollapse */
MtxButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MtxButtonModule
});
/** @nocollapse */
MtxButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
      exports: [MatButtonLoadingDirective],
      declarations: [MatButtonLoadingDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MatButtonLoadingDirective, MtxButtonModule };
