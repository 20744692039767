!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define([], t) : "object" == typeof exports ? exports.dms = t() : e.dms = t();
}(self, () => (() => {
  "use strict";

  var e = {
      d: (t, r) => {
        for (var n in r) e.o(r, n) && !e.o(t, n) && Object.defineProperty(t, n, {
          enumerable: !0,
          get: r[n]
        });
      },
      o: (e, t) => Object.prototype.hasOwnProperty.call(e, t)
    },
    t = {};
  e.d(t, {
    default: () => i
  });
  var r = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′ʹ:]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW])?/i;
  function n(e) {
    return e > 0 ? Math.floor(e) : Math.ceil(e);
  }
  var o = function () {
    function e(e, t) {
      this._dd = e, this._hemisphere = /^[WE]|(?:lon)/i.test(t) ? e < 0 ? "W" : "E" : e < 0 ? "S" : "N";
    }
    return Object.defineProperty(e.prototype, "dd", {
      get: function () {
        return this._dd;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "hemisphere", {
      get: function () {
        return this._hemisphere;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.getDmsArray = function () {
      return this.dmsArray;
    }, Object.defineProperty(e.prototype, "dmsArray", {
      get: function () {
        var e = Math.abs(this._dd),
          t = n(e),
          r = n(60 * (e - t));
        return [t, r, (e - t - r / 60) * Math.pow(60, 2), this._hemisphere];
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.toString = function (e) {
      var t = this.getDmsArray(),
        r = isNaN(Number(e)) ? t[2] : t[2].toFixed(e);
      return "".concat(t[0], "°").concat(t[1], "′").concat(r, "″ ").concat(t[3]);
    }, e;
  }();
  const i = function () {
    function e(e, t) {
      if (this.lat = e, this.lon = t, "number" != typeof e || "number" != typeof t) throw TypeError("The longitude and latitude parameters must be numbers.");
      if (isNaN(t) || t < -180 || t > 180) throw RangeError("longitude must be between -180 and 180");
      if (isNaN(e) || e < -90 || e > 90) throw RangeError("latitude must be between -90 and 90");
      this._longitude = new o(t, "long"), this._latitude = new o(e, "lat");
    }
    return Object.defineProperty(e.prototype, "longitude", {
      get: function () {
        return this._longitude;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "latitude", {
      get: function () {
        return this._latitude;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.getDmsArrays = function () {
      return this.dmsArrays;
    }, Object.defineProperty(e.prototype, "dmsArrays", {
      get: function () {
        return {
          longitude: this.longitude.dmsArray,
          latitude: this.latitude.dmsArray
        };
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.toString = function () {
      return [this.latitude, this.longitude].join(", ");
    }, e.dmsRe = r, e;
  }();
  return t.default;
})());