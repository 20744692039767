import { debounceTime, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Control, ControlPosition, DomEvent, DomUtil, LatLng, Map, Marker, marker } from 'leaflet';
import { Subject } from 'rxjs';
import DmsCoordinates from 'dms-conversion';

import parseDMS from 'parse-dms';

@Component( {
    selector: 'wnd-coords-marker-input',
    templateUrl: './coords-marker-input.component.html',
    styleUrls: [ './coords-marker-input.component.scss' ],
} )
export class CoordsMarkerInputComponent implements OnInit, OnDestroy {
    private _map: Map;
    public custom: Control;
    userTyping = false;
    private _markerDrawer: any;
    private _tempMarker: Marker;
    private _initialView: { center: LatLng; bounds: L.LatLngBounds };
    private _mouseMovingFilter = new Subject<LatLng>();
    coordsState = 'unused';
    private _selectedCoords: LatLng;
    @Output() drawingEnd = new EventEmitter<void>();
    @Input() drawControl: L.Control.Draw;
    @Input() position: ControlPosition;
    coordsControl = new UntypedFormControl();
    @ViewChild( 'coordsInput', { static: true } ) coordsInput: MatInput;

    constructor( private cdr: ChangeDetectorRef ) {
    }

    ngOnInit(): void {
        this.coordsInput.focus();
        this.coordsInput.stateChanges.subscribe( () => this.coordsInput.focus() );
        this.coordsControl.valueChanges
            .pipe(
                tap( () => {
                    this.coordsState = 'unused';
                    if( this._tempMarker ) {
                        this._tempMarker.removeFrom( this._map );
                    }
                    this._map.panTo( this._initialView.center ).fitBounds( this._initialView.bounds );
                } ),
                debounceTime( 400 ),
            )
            .subscribe( ( val: string ) => {
                this._checkCoords( val );
            } );

        this._mouseMovingFilter.pipe( debounceTime( 50 ) ).subscribe( latLng => {
            this.coordsInput.value = this._computeCoordsString( latLng );
        } );
    }

    @Input() set map( map: Map ) {
        if( map ) {
            this._map = map;
            const custom = Control.extend( {
                onAdd( _map: Map ) {
                    const ctrl = DomUtil.get( 'custom' );
                    DomEvent.disableClickPropagation( ctrl );
                    DomEvent.disableScrollPropagation( ctrl );
                    return ctrl;
                },
                onRemove( _map: Map ) {
                },
            } );
            this.custom = new custom( {
                position: this.position,
            } ).addTo( map );
            map.on( 'mousemove', this.onMouseMove, this );
            map.on( 'draw:drawstop', this.onMouseSelection, this );
        }
    }

    ngOnDestroy() {
        this._map.removeControl( this.custom );
        this._map.off( 'mousemove', this.onMouseMove, this );
        this._map.off( 'draw:drawstop', this.onMouseSelection, this );
    }

    private _computeCoordsString( latLng: LatLng ): string {
        const str = new DmsCoordinates( latLng.lat, latLng.lng ).toString();
        try {
            let cs = str.split( ', ' );
            cs = cs.map( c => {
                const point = c.indexOf( '.' );
                const quotes = c.indexOf( '″' );
                if( point > -1 ) {
                    c = c.slice( 0, point + 3 ) + c.slice( quotes );
                }
                c = c.replace( '′', '\'' );
                c = c.replace( '″', '"' );
                c = c.replace( 'W', 'O' );
                c = c.replace( ' ', '' );
                return c;
            } );
            return cs.join( ' ' );
        } catch {
            return str;
        }
    }

    onMouseMove( e ) {
        this._mouseMovingFilter.next( window.L.latLng( e.latlng.lat, e.latlng.lng ) );
    }


    onMouseSelection( _e ) {
        if( !this.userTyping ) {
            this.drawingEnd.emit();
        }
    }

    private _initDrawers() {
        if( !this._markerDrawer ) {
            this._markerDrawer = ( this.drawControl as any )._toolbars.draw._modes.marker.handler;
            this._initialView = { center: this._map.getCenter(), bounds: this._map.getBounds() };
        }
    }

    private _disableDrawer() {
        this._markerDrawer.disable();
        this._map.removeControl( this.drawControl );
    }

    public enableDrawer() {
        if( this._tempMarker ) {
            this._tempMarker.removeFrom( this._map );
        }
        this._map.addControl( this.drawControl );
        this._markerDrawer.enable();
        this.userTyping = false;
        this._map.on( 'mousemove', this.onMouseMove, this );
        this.coordsState = 'unused';
    }

    userKeyDown( e ) {
        this._initDrawers();
        if( e.key === 'Escape' ) {
            if( !this.userTyping ) {
                this._markerDrawer.disable();
                this.drawingEnd.emit();
            }
        } else {
            if( !this.userTyping ) {
                this.userTyping = true;
                this._map.off( 'mousemove', this.onMouseMove, this );
                this._disableDrawer();
                this.coordsInput.value = '';
            }
            if( e.key === 'Enter' ) {
                this.submitCoords();
            }
        }
    }

    private _isValidCoords( val: string ): LatLng {
        val.replace( 'O', 'W' );
        let parsed: any;
        try {
            parsed = parseDMS( val );
        } catch {
            return undefined;
        }
        if( parsed?.lat && parsed?.lon ) {
            return new LatLng( parsed?.lat, parsed?.lon );
        }
        return undefined;
    }

    private _checkCoords( val: string ) {
        val = val.trim().toUpperCase();
        if( val !== '' ) {
            this._selectedCoords = this._isValidCoords( val );
            if( this._selectedCoords ) {
                this._tempMarker = marker( this._selectedCoords, {
                    icon: this._markerDrawer.options.icon,
                } ).addTo( this._map );
                this._map.panTo( this._selectedCoords ).fitBounds( this._selectedCoords.toBounds( 3000 ) );
                this.coordsState = 'valid';
            } else {
                this.coordsState = 'not valid';
            }
        }
        this.cdr.detectChanges();
    }

    submitCoords() {
        this._tempMarker.removeFrom( this._map );
        this.enableDrawer();
        this.drawingEnd.emit();
        this._map.fireEvent( 'click', {
            latlng: this._selectedCoords,
        } );
    }
}
