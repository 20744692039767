import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ViewChild, NgModule, NO_ERRORS_SCHEMA, Type, Directive, ViewChildren } from '@angular/core';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FieldWrapper, ɵdefineHiddenProp, FormlyModule, FieldType as FieldType$1, ɵobserve } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import * as i2 from '@angular/material/form-field';
import { MatFormField, MatFormFieldModule, MatFormFieldControl } from '@angular/material/form-field';
import * as i1 from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
const _c0 = a0 => ({
  field: a0
});
const _c1 = a0 => ({
  content: a0
});
function FormlyWrapperFormField_mat_label_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtext(1, "*");
    i0.ɵɵelementEnd();
  }
}
function FormlyWrapperFormField_mat_label_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-label");
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, FormlyWrapperFormField_mat_label_3_span_2_Template, 2, 0, "span", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.props.required && ctx_r0.props.hideRequiredMarker !== true);
  }
}
function FormlyWrapperFormField_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 13);
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.props.textPrefix)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.field));
  }
}
function FormlyWrapperFormField_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 15);
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.props.prefix)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.field));
  }
}
function FormlyWrapperFormField_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 16);
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.props.textSuffix)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.field));
  }
}
function FormlyWrapperFormField_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 17);
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.props.suffix)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.field));
  }
}
function FormlyWrapperFormField_mat_hint_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-hint");
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const hint_r2 = ctx.ngIf;
    i0.ɵɵnextContext();
    const stringOrTemplate_r3 = i0.ɵɵreference(13);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", stringOrTemplate_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, hint_r2));
  }
}
function FormlyWrapperFormField_mat_hint_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-hint", 18);
    i0.ɵɵelementContainer(1, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const hintEnd_r4 = ctx.ngIf;
    i0.ɵɵnextContext();
    const stringOrTemplate_r3 = i0.ɵɵreference(13);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", stringOrTemplate_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, hintEnd_r4));
  }
}
function FormlyWrapperFormField_ng_template_12_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const content_r5 = i0.ɵɵnextContext().content;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(content_r5);
  }
}
function FormlyWrapperFormField_ng_template_12_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 14);
  }
  if (rf & 2) {
    const content_r5 = i0.ɵɵnextContext().content;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", content_r5)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.field));
  }
}
function FormlyWrapperFormField_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyWrapperFormField_ng_template_12_ng_container_0_Template, 2, 1, "ng-container", 19)(1, FormlyWrapperFormField_ng_template_12_ng_template_1_Template, 1, 4, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const content_r5 = ctx.content;
    const template_r6 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngIf", !content_r5.createEmbeddedView)("ngIfElse", template_r6);
  }
}
const _c2 = ["matPrefix"];
const _c3 = ["matTextPrefix"];
const _c4 = ["matSuffix"];
const _c5 = ["matTextSuffix"];
class FormlyWrapperFormField extends FieldWrapper {
  constructor(renderer, elementRef, focusMonitor) {
    super();
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.focusMonitor = focusMonitor;
  }
  ngOnInit() {
    ɵdefineHiddenProp(this.field, '_formField', this.formField);
    this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {
      if (!origin && this.field.focus) {
        this.field.focus = false;
      }
    });
  }
  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/7891
    if (this.formField.appearance !== 'outline' && this.props.hideFieldUnderline === true) {
      const underlineElement = this.formField._elementRef.nativeElement.querySelector('.mat-form-field-underline');
      underlineElement && this.renderer.removeChild(underlineElement.parentNode, underlineElement);
    }
  }
  ngOnDestroy() {
    delete this.field._formField;
    this.focusMonitor.stopMonitoring(this.elementRef);
  }
}
FormlyWrapperFormField.ɵfac = function FormlyWrapperFormField_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyWrapperFormField)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.FocusMonitor));
};
FormlyWrapperFormField.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyWrapperFormField,
  selectors: [["formly-wrapper-mat-form-field"]],
  viewQuery: function FormlyWrapperFormField_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatFormField, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.formField = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 14,
  vars: 13,
  consts: [["fieldComponent", ""], ["stringOrTemplate", ""], ["template", ""], [3, "hideRequiredMarker", "floatLabel", "appearance", "subscriptSizing", "color"], [4, "ngIf"], ["matTextPrefix", "", 4, "ngIf"], ["matPrefix", "", 4, "ngIf"], ["matTextSuffix", "", 4, "ngIf"], ["matSuffix", "", 4, "ngIf"], [3, "field"], ["align", "end", 4, "ngIf"], ["aria-hidden", "true", "class", "mat-form-field-required-marker mat-mdc-form-field-required-marker", 4, "ngIf"], ["aria-hidden", "true", 1, "mat-form-field-required-marker", "mat-mdc-form-field-required-marker"], ["matTextPrefix", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], ["matPrefix", ""], ["matTextSuffix", ""], ["matSuffix", ""], ["align", "end"], [4, "ngIf", "ngIfElse"]],
  template: function FormlyWrapperFormField_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "mat-form-field", 3);
      i0.ɵɵelementContainer(1, null, 0);
      i0.ɵɵtemplate(3, FormlyWrapperFormField_mat_label_3_Template, 3, 2, "mat-label", 4)(4, FormlyWrapperFormField_ng_container_4_Template, 2, 4, "ng-container", 5)(5, FormlyWrapperFormField_ng_container_5_Template, 2, 4, "ng-container", 6)(6, FormlyWrapperFormField_ng_container_6_Template, 2, 4, "ng-container", 7)(7, FormlyWrapperFormField_ng_container_7_Template, 2, 4, "ng-container", 8);
      i0.ɵɵelementStart(8, "mat-error");
      i0.ɵɵelement(9, "formly-validation-message", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(10, FormlyWrapperFormField_mat_hint_10_Template, 2, 4, "mat-hint", 4)(11, FormlyWrapperFormField_mat_hint_11_Template, 2, 4, "mat-hint", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(12, FormlyWrapperFormField_ng_template_12_Template, 3, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      let tmp_6_0;
      i0.ɵɵproperty("hideRequiredMarker", true)("floatLabel", ctx.props.floatLabel)("appearance", ctx.props.appearance)("subscriptSizing", ctx.props.subscriptSizing)("color", (tmp_6_0 = ctx.props.color) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "primary");
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.props.label && ctx.props.hideLabel !== true);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.textPrefix);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.prefix);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.textSuffix);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.suffix);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("field", ctx.field);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.description || ctx.props.hintStart);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.hintEnd);
    }
  },
  dependencies: [i2.MatFormField, i3.ɵFormlyValidationMessage, i4.NgIf, i2.MatLabel, i4.NgTemplateOutlet, i2.MatPrefix, i2.MatSuffix, i2.MatError, i2.MatHint],
  styles: ["formly-wrapper-mat-form-field .mat-mdc-form-field,formly-wrapper-mat-form-field .mat-form-field{width:100%}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyWrapperFormField, [{
    type: Component,
    args: [{
      selector: 'formly-wrapper-mat-form-field',
      template: `
    <!-- fix https://github.com/angular/material2/pull/7083 by setting width to 100% -->
    <mat-form-field
      [hideRequiredMarker]="true"
      [floatLabel]="props.floatLabel"
      [appearance]="props.appearance"
      [subscriptSizing]="props.subscriptSizing"
      [color]="props.color ?? 'primary'"
    >
      <ng-container #fieldComponent></ng-container>
      <mat-label *ngIf="props.label && props.hideLabel !== true">
        {{ props.label }}
        <span
          *ngIf="props.required && props.hideRequiredMarker !== true"
          aria-hidden="true"
          class="mat-form-field-required-marker mat-mdc-form-field-required-marker"
          >*</span
        >
      </mat-label>

      <ng-container matTextPrefix *ngIf="props.textPrefix">
        <ng-container [ngTemplateOutlet]="props.textPrefix" [ngTemplateOutletContext]="{ field: field }"></ng-container>
      </ng-container>

      <ng-container matPrefix *ngIf="props.prefix">
        <ng-container [ngTemplateOutlet]="props.prefix" [ngTemplateOutletContext]="{ field: field }"></ng-container>
      </ng-container>

      <ng-container matTextSuffix *ngIf="props.textSuffix">
        <ng-container [ngTemplateOutlet]="props.textSuffix" [ngTemplateOutletContext]="{ field: field }"></ng-container>
      </ng-container>

      <ng-container matSuffix *ngIf="props.suffix">
        <ng-container [ngTemplateOutlet]="props.suffix" [ngTemplateOutletContext]="{ field: field }"></ng-container>
      </ng-container>

      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>

      <mat-hint *ngIf="props.description || props.hintStart as hint">
        <ng-container [ngTemplateOutlet]="stringOrTemplate" [ngTemplateOutletContext]="{ content: hint }">
        </ng-container>
      </mat-hint>

      <mat-hint *ngIf="props.hintEnd as hintEnd" align="end">
        <ng-container [ngTemplateOutlet]="stringOrTemplate" [ngTemplateOutletContext]="{ content: hintEnd }">
        </ng-container>
      </mat-hint>
    </mat-form-field>

    <ng-template #stringOrTemplate let-content="content">
      <ng-container *ngIf="!content.createEmbeddedView; else template">{{ content }}</ng-container>
      <ng-template #template>
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ field: field }"></ng-container>
      </ng-template>
    </ng-template>
  `,
      encapsulation: ViewEncapsulation.None,
      styles: ["formly-wrapper-mat-form-field .mat-mdc-form-field,formly-wrapper-mat-form-field .mat-form-field{width:100%}\n"]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }, {
      type: i1.FocusMonitor
    }];
  }, {
    formField: [{
      type: ViewChild,
      args: [MatFormField, {
        static: true
      }]
    }]
  });
})();
class FormlyMatFormFieldModule {}
FormlyMatFormFieldModule.ɵfac = function FormlyMatFormFieldModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyMatFormFieldModule)();
};
FormlyMatFormFieldModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatFormFieldModule
});
FormlyMatFormFieldModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatFormFieldModule, FormlyModule.forChild({
    wrappers: [{
      name: 'form-field',
      component: FormlyWrapperFormField
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatFormFieldModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyWrapperFormField],
      imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, FormlyModule.forChild({
        wrappers: [{
          name: 'form-field',
          component: FormlyWrapperFormField
        }]
      })],
      schemas: [NO_ERRORS_SCHEMA]
    }]
  }], null, null);
})();
class FieldType extends FieldType$1 {
  constructor() {
    super(...arguments);
    this.errorStateMatcher = {
      isErrorState: () => this.field && this.showError
    };
    this.stateChanges = new Subject();
    this._errorState = false;
    this._focused = false;
  }
  set matPrefix(prefix) {
    if (prefix) {
      this.props.prefix = prefix;
    }
  }
  set matTextPrefix(textPrefix) {
    if (textPrefix) {
      this.props.textPrefix = textPrefix;
    }
  }
  set matSuffix(suffix) {
    if (suffix) {
      this.props.suffix = suffix;
    }
  }
  set matTextSuffix(textSuffix) {
    if (textSuffix) {
      this.props.textSuffix = textSuffix;
    }
  }
  set _controls(controls) {
    this.attachControl(controls.length === 1 ? controls.first : this);
  }
  ngOnDestroy() {
    delete this.formField?._control;
    this.stateChanges.complete();
  }
  setDescribedByIds(_ids) {}
  onContainerClick(_event) {
    this.field.focus = true;
    this.stateChanges.next();
  }
  get errorState() {
    const showError = this.options.showError(this);
    if (showError !== this._errorState) {
      this._errorState = showError;
      this.stateChanges.next();
    }
    return showError;
  }
  get controlType() {
    if (this.props.type) {
      return this.props.type;
    }
    const type = this.field.type;
    return type instanceof Type ? type.prototype.constructor.name : type;
  }
  get focused() {
    const focused = !!this.field.focus && !this.disabled;
    if (focused !== this._focused) {
      this._focused = focused;
      this.stateChanges.next();
    }
    return focused;
  }
  get disabled() {
    return !!this.props.disabled;
  }
  get required() {
    return !!this.props.required;
  }
  get placeholder() {
    return this.props.placeholder || '';
  }
  get shouldPlaceholderFloat() {
    return this.shouldLabelFloat;
  }
  get value() {
    return this.formControl?.value;
  }
  set value(value) {
    this.formControl?.patchValue(value);
  }
  get ngControl() {
    return this.formControl;
  }
  get empty() {
    return this.value == null || this.value === '';
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  get formField() {
    return this.field?.['_formField'];
  }
  attachControl(control) {
    if (this.formField && control !== this.formField._control) {
      this.formField._control = control;
      // temporary fix for https://github.com/angular/material2/issues/6728
      const ngControl = control?.ngControl;
      if (ngControl?.valueAccessor?.hasOwnProperty('_formField')) {
        ngControl.valueAccessor['_formField'] = this.formField;
      }
      if (ngControl?.valueAccessor?.hasOwnProperty('_parentFormField')) {
        ngControl.valueAccessor['_parentFormField'] = this.formField;
      }
      ['prefix', 'suffix', 'textPrefix', 'textSuffix'].forEach(type => ɵobserve(this.field, ['props', type], ({
        currentValue
      }) => currentValue && Promise.resolve().then(() => {
        this.options.detectChanges(this.field);
      })));
      // https://github.com/angular/components/issues/16209
      const setDescribedByIds = control.setDescribedByIds.bind(control);
      control.setDescribedByIds = ids => {
        setTimeout(() => setDescribedByIds(ids));
      };
    }
  }
}
FieldType.ɵfac = /* @__PURE__ */(() => {
  let ɵFieldType_BaseFactory;
  return function FieldType_Factory(__ngFactoryType__) {
    return (ɵFieldType_BaseFactory || (ɵFieldType_BaseFactory = i0.ɵɵgetInheritedFactory(FieldType)))(__ngFactoryType__ || FieldType);
  };
})();
FieldType.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FieldType,
  viewQuery: function FieldType_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
      i0.ɵɵviewQuery(_c3, 5);
      i0.ɵɵviewQuery(_c4, 5);
      i0.ɵɵviewQuery(_c5, 5);
      i0.ɵɵviewQuery(MatFormFieldControl, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matPrefix = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matTextPrefix = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matSuffix = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matTextSuffix = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._controls = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FieldType, [{
    type: Directive
  }], null, {
    matPrefix: [{
      type: ViewChild,
      args: ['matPrefix']
    }],
    matTextPrefix: [{
      type: ViewChild,
      args: ['matTextPrefix']
    }],
    matSuffix: [{
      type: ViewChild,
      args: ['matSuffix']
    }],
    matTextSuffix: [{
      type: ViewChild,
      args: ['matTextSuffix']
    }],
    _controls: [{
      type: ViewChildren,
      args: [MatFormFieldControl]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FieldType, FormlyMatFormFieldModule };
