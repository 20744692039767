/* tslint:disable:no-string-literal */
import { featureGroup, Marker } from 'leaflet';
import { CustomMapIcon } from './icons-svg';

export interface FeaturesGroup {
    id?: string;
    group: L.FeatureGroup;
    typeOfService: string;
    idStep: string;
}

export const transformLayerToJson = ( featGroup: L.FeatureGroup<any> ) => {
    const group = featGroup.toGeoJSON();
    let cnt = 0;
    featGroup.getLayers().forEach( l => {
        if( l instanceof window.L.Circle ) {
            group[ 'features' ].features[ cnt ].properties.radius = l.getRadius();
        }
        cnt++;
    } );

    return group;
};

export const transformJsonToLayer = ( json ): L.FeatureGroup<any>  => {
    const group = featureGroup();

    json.features.forEach( f =>
        window.L.geoJSON( f, {
            pointToLayer: ( feature, latlng ) => {
                if( feature.properties.radius ) {
                    return new window.L.Circle( latlng, feature.properties.radius );
                } else {
                    return new window.L.Marker( latlng );
                }
            },
            onEachFeature: ( feature, layer ) => {
                group.addLayer( layer );
            },
        } )
    );

    return group;
};


export const fixMarker =( ing: L.FeatureGroup<any> ): L.FeatureGroup<any> => {
    ing.getLayers().forEach( l => {
        if( l instanceof Marker ) {
            ( l  ).setIcon( CustomMapIcon.newPlaceIcon );
        }
    } );
    return ing;
};
